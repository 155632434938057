import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogBody,
  StandardForm,
  FormField,
  FormLabel,
  FormInputGroup,
  TextInput,
  FormError,
  ModalDialogButtons,
  ModalDialogFooter,
  Button,
  ModalDialogCloseButton,
  ModalDialogNav,
} from '@vp/swan'
import { useState } from 'react'
import SpinnerButton from './SpinnerButton'
import { useLogger } from '@vp/ubik-context'
import { useLocalization } from '../hooks/useLocalizations'

type NameInputModalProps = {
  title: string;
  submitButtonLabel: string;
  isOpen: boolean;
  errorMessage: string;
  placeholder?: string;
  onSubmit: (input: string) => Promise<void>;
  onClose: () => void;
  'data-testid'?: string;
}

const NameInputModal = (props: NameInputModalProps) => {
  const MAX_INPUT_LENGTH = 255
  const [newName, setNewName] = useState('')
  const [completing, setCompleting] = useState(false)
  const [showError, setShowError] = useState(false)
  const { t } = useLocalization()
  const logger = useLogger()
  const onComplete = async () => {
    setShowError(false)
    setCompleting(true)
    try {
      await props.onSubmit(newName)
      handleClose()
    } catch (error) {
      logger.error('Error while submitting in name input modal', error as Error)
      setShowError(true)
    }

    setCompleting(false)
  }

  const handleClose = () => {
    setNewName('')
    setShowError(false)
    props.onClose()
  }

  return (
    <ModalDialog
      data-testid={props['data-testid']}
      isOpen={props.isOpen}
      onRequestDismiss={props.onClose}
      onSubmit={(e: any) => {
        e.preventDefault() // This makes sure that if you hit enter, the page doesn't reload itself
        onComplete && onComplete()
      }}
      onlyRenderWhenOpen
    >
      <ModalDialogContent aria-label={props.title}>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t('projectDetailsModal.close')} />
        </ModalDialogNav>
        <ModalDialogHeader>{props.title}</ModalDialogHeader>
        <ModalDialogBody>
          <StandardForm>
            <FormField>
              <FormLabel>{t('nameInputModal.subtitleText')}</FormLabel>
              <FormInputGroup>
                <TextInput
                  data-testid='text-input'
                  mt='3'
                  placeholder={props.placeholder || undefined}
                  value={newName}
                  onChange={(event: any) => {
                    setShowError(false)
                    setNewName(event.target.value)
                  }}
                  maxLength={MAX_INPUT_LENGTH}
                  disabled={completing}
                  onKeyDown={(e: any) => {
                    e.stopPropagation()
                  }}
                />
                {showError && <FormError data-testid='name-input-modal-error'>{props.errorMessage}</FormError>}
              </FormInputGroup>
            </FormField>
          </StandardForm>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <SpinnerButton
              disabled={!props.placeholder && !newName}
              data-testid='name-input-modal-submit'
              onClick={onComplete}
              skin='primary'
            >
              {props.submitButtonLabel}
            </SpinnerButton>
            <Button data-testid='name-input-modal-close' onClick={handleClose} disabled={completing}>
              {t('nameInputModal.cancelButtonText')}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default NameInputModal

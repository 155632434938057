import { Card, FlexBox, Link } from '@vp/swan'
import { Project } from '../../../domain/model/project'
import { useTracking } from '../../../context/TrackingContext'
import { useDesignServicesWrapper } from '../../../hooks/useDesignServicesWrapper'
import { DesignHelpIcon } from '../../../icons/icon-designhelp'
import { getDesignHelpEventLabel, trackProjectEvent } from '../../Analytics/trackingUtils'

export const DesignServicesCTABanner = ({ project }: { project: Project }) => {
  const { templateEditsEnabled, templateEditsBriefUrl, needDesignHelpText, designHelpAction } =
    useDesignServicesWrapper(project)
  const { labels } = useTracking()

  if (!templateEditsEnabled) {
    return <></>
  }

  return (
    <Card>
      <FlexBox alignItems='center' gap='3'>
        <DesignHelpIcon />
        <Link
          withIcon
          skin='cta'
          href={templateEditsBriefUrl}
          onClick={() =>
            designHelpAction && trackProjectEvent(getDesignHelpEventLabel(designHelpAction, labels), project)}
        >
          {needDesignHelpText}
        </Link>
      </FlexBox>
    </Card>
  )
}

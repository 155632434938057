import React, { useState } from 'react'
import Alert from '../components/Alert'

type AlertContextProps = {
  showAlert: (message: string) => void;
}

export const AlertContext = React.createContext<AlertContextProps>({
  showAlert: () => {},
})

export const AlertContextProvider = (props: { children: any }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [message, setMessage] = useState('')

  const showAlert = (message: string) => {
    setIsOpened(true)
    setMessage(message)
  }

  return (
    <AlertContext.Provider value={{ showAlert }}>
      <Alert message={message} isOpened={isOpened} onDismiss={() => setIsOpened(false)} />
      {props.children}
    </AlertContext.Provider>
  )
}

import { IProject } from './iproject'

export class PaginatedProjectsList<T extends IProject> {
  private projects: Array<T>

  constructor (projectsList?: PaginatedProjectsList<T>) {
    this.projects = projectsList?.projects || []
  }

  count = () => this.projects.length

  add = (projects: T[]) => {
    this.projects.push(...projects)

    const distinctProjects: T[] = []
    for (const project of this.projects) {
      if (!distinctProjects.find((p) => p.id === project.id)) {
        distinctProjects.push(project)
      }
    }
    this.projects = distinctProjects

    this.projects.sort((x, y) => y.modified.getTime() - x.modified.getTime())
    return new PaginatedProjectsList<T>(this)
  }

  filter = (filter: string) => {
    if (!filter) return this

    filter = this.normalizeText(filter)

    const filteredProjects = this.projects.filter(
      (x) => this.normalizeText(x.name).includes(filter) || this.normalizeText(x.productName).includes(filter)
    )
    const newProjectList = new PaginatedProjectsList<T>()
    newProjectList.projects = filteredProjects

    return newProjectList
  }

  normalizeText = (text: string | undefined) =>
    text
      ? text
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase() || ''
      : ''

  elementAt = (index: number) => this.projects[index]

  getNumberOfPages = (pageSize: number) => (this.projects.length && pageSize ? Math.ceil(this.count() / pageSize) : 1)

  getPage = (pageSize: number, pageNumber: number) => {
    const firstElement = pageSize * (pageNumber - 1)
    const lastElement = pageSize * pageNumber

    return this.projects.slice(firstElement, lastElement)
  }

  toArray = (): Array<T> => {
    return this.projects
  }
}

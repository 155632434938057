import { Button, Icon } from '@vp/swan'
import { Project } from '../../domain/model/project'
import { getDesignHelpEventLabel, trackProjectEvent } from '../Analytics/trackingUtils'
import { useTracking } from '../../context/TrackingContext'
import { useDesignServicesWrapper } from '../../hooks/useDesignServicesWrapper'

const DesignServicesButton = (props: { project: Project }) => {
  const { templateEditsEnabled, templateEditsBriefUrl, needDesignHelpText, designHelpAction } =
    useDesignServicesWrapper(props.project)

  const { labels } = useTracking()

  if (!templateEditsEnabled) {
    return null
  }

  return (
    <Button
      iconPosition='left'
      skin='tertiary'
      data-testid='design-services-button'
      onClick={() => {
        designHelpAction && trackProjectEvent(getDesignHelpEventLabel(designHelpAction, labels), props.project)
        window.location.assign(templateEditsBriefUrl)
      }}
    >
      <Icon iconType='workWithAPro' size='20p' mr='3' />
      {needDesignHelpText}
    </Button>
  )
}

export default DesignServicesButton

import { useLocalStorage } from '@vp/cache-agent'
import { fetchOptionNamesFromServer } from '../clients/msx.proxy'
import { mapTranslatedOptions } from '../domain/services/msxService'
import { Project } from '../domain/model/project'
import { useLogger, useUserContext } from '@vp/ubik-context'

const BASE_KEY = 'vp-translated-options'

const useTranslatedProductOptions = (project: Project) => {
  const logger = useLogger()
  const { locale, tenant } = useUserContext()
  const translationRequest = () => {
    if (project.productVersion === undefined) return Promise.resolve(undefined)
    return fetchOptionNamesFromServer(tenant as string, locale, project.productKey, project.productVersion.toString(), logger)
  }

  const localStorageKey = `${BASE_KEY}-${tenant}-${locale}-${project.productKey}-${project.productVersion}`

  const translatedOptionsNames = useLocalStorage(localStorageKey, translationRequest)

  if (!translatedOptionsNames) return project.options

  return mapTranslatedOptions(project.options, translatedOptionsNames)
}

export { useTranslatedProductOptions }

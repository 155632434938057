import { useEffect, useState } from 'react'
import { Project } from '../domain/model/project'
import { getFirstPageOfWesProjects, getRestOfWesProjects } from '../domain/services/wesProjectsService'
import { fetchWesProjectCount } from '../clients/wesProjects.proxy'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { useOwner } from './useOwner'
import { DEFAULT_TENANT } from '../constants'
type wesProjectsState = {
  wesProjects: Project[];
  wesProjectsCount: number;
  firstPageLoaded: boolean;
  allWesProjectsLoaded: boolean;
}

const useWesProjects = () => {
  const [isRefreshing, setIsRefreshing] = useState(true)
  const [data, setData] = useState<wesProjectsState>({
    wesProjects: [],
    wesProjectsCount: 0,
    firstPageLoaded: false,
    allWesProjectsLoaded: false,
  })
  const [wesError, setWesError] = useState(false)
  const { locale, tenant: contextTenant } = useUserContext()
  const tenant = contextTenant || DEFAULT_TENANT
  const { isIdentityInitialized, identity } = useIdentity()
  const logger = useLogger()

  const owner = useOwner()

  const refreshWes = () => {
    setData({
      wesProjects: [],
      wesProjectsCount: 0,
      firstPageLoaded: false,
      allWesProjectsLoaded: false,
    })
    setIsRefreshing(true)
  }

  useEffect(() => {
    let isMounted = true

    if (!isIdentityInitialized || !isRefreshing) return
    setData({ ...data, firstPageLoaded: false })

    const getProjectsFromService = async () => {
      let tempWesProjects: Project[] = []
      let tempWesProjectsCount: number = 0

      try {
        [tempWesProjects, tempWesProjectsCount] = await Promise.all([
          getFirstPageOfWesProjects(tenant, locale, owner, logger, identity?.authorizationHeader as string),
          fetchWesProjectCount(tenant, owner, logger, identity?.authorizationHeader as string),
        ])
      } catch (error) {
        logger.error('Failed to get wes projects', error as Error)
        setWesError(true)
      }
      if (isMounted) {
        setData({
          ...data,
          wesProjects: tempWesProjects,
          wesProjectsCount: tempWesProjectsCount,
          firstPageLoaded: true,
        })
        setIsRefreshing(false)
      }
    }

    getProjectsFromService()

    return () => {
      isMounted = false
    }
  }, [isIdentityInitialized, owner, tenant, locale, isRefreshing])

  useEffect(() => {
    if (!data.firstPageLoaded) return
    let isMounted = true

    const getRestOfWesProjectsFromService = async () => {
      let tempRestOfProjects: Project[] = []
      try {
        tempRestOfProjects = await getRestOfWesProjects(
          data.wesProjectsCount,
          tenant,
          locale,
          owner,
          logger,
          identity?.authorizationHeader as string
        )
      } catch (error) {
        logger.error('Failed to get rest of wes projects', error as Error)
        setWesError(true)
      }
      if (isMounted) {
        setData({ ...data, wesProjects: [...data.wesProjects, ...tempRestOfProjects], allWesProjectsLoaded: true })
      }
    }

    getRestOfWesProjectsFromService()

    return () => {
      isMounted = false
    }
  }, [data.firstPageLoaded])
  const { wesProjects, wesProjectsCount, firstPageLoaded, allWesProjectsLoaded } = data
  return { wesProjects, wesProjectsCount, firstPageLoaded, allWesProjectsLoaded, refreshWes, wesError }
}

export { useWesProjects }

/* eslint-disable @typescript-eslint/no-redeclare */
import React from 'react'
import { LegacyRecreationModal } from '../components/LegacyRecreationModal'
export const LegacyRecreationModalContext = React.createContext<LegacyRecreationModalContext>({})
export const LegacyRecreationModalContextProvider = ({
  children,
  isOpen,
  onRequestDismiss,
  culture,
}: {
  children: any;
  isOpen: boolean;
  onRequestDismiss: any;
  culture: string;
  onCompletion?: () => void;
  documentName: string;
  legacyDocumentId: string;
  legacyProductName: string;
  requestor: string;
}) => {
  return (
    <LegacyRecreationModalContext.Provider value={{}}>
      <LegacyRecreationModal isOpen={isOpen} onRequestDismiss={onRequestDismiss} culture={culture} />
      {children}
    </LegacyRecreationModalContext.Provider>
  )
}

export interface LegacyRecreationModalContext {}

import { Box, FlexBox, ProgressiveImage, ResponsiveImage } from '@vp/swan'
import React from 'react'
import { AnimateKeyframes } from 'react-simple-animate'
import { FinishedProjectIcon } from '../icons/icon-projectFinished'
import { ScalableFinishedProjectIcon } from '../icons/icon-scaleableProjectFinished'

const svg = '<svg xmlns="http://www.w3.org/2000/svg" width="680.764" height="528.354" viewBox="0 0 180.119 139.794"><g transform="translate(-13.59 -66.639)"><path style="opacity:1;fill:#d0d0d0;fill-opacity:1;stroke:none;stroke-width:2.96123242;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke" d="M13.591 66.639H193.71v139.794H13.591z"/><path style="opacity:.675;fill:#fff;fill-opacity:1;stroke:none;stroke-width:2.98038435;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke" d="m118.507 133.514-34.249 34.249-15.968-15.968-41.938 41.937h152.374z"/><circle style="opacity:.675;fill:#fff;fill-opacity:1;stroke:none;stroke-width:1.99717033;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke" cx="58.217" cy="108.555" r="11.773"/><path style="opacity:1;fill:none;fill-opacity:1;stroke:none;stroke-width:2.96123242;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke" d="M26.111 77.634h152.614v116.099H26.111z"/></g></svg>'
const PLACEHOLDER = `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`

export const StaticBadge = () => {
  return (
    <Box style={{ position: 'absolute', top: '7px', right: '32px' }}>
      <FinishedProjectIcon />
    </Box>
  )
}

export const ResponsiveBadge = () => {
  return (
    <Box>
      <ScalableFinishedProjectIcon height='20%' width='20%' style={{ top: '10px', right: 0, position: 'absolute' }} />
    </Box>
  )
}

export type LazyLoadingImageProps = {
  src: string;
  srcSet?: string;
  alt: string;
  useLoadingAnimation?: boolean;
  hasBadge: boolean;
  badgeComponent?: React.ComponentType;
  onClick?: () => void;
}

export const LazyLoadingImage: React.FC<LazyLoadingImageProps> = (props: LazyLoadingImageProps) => {
  const animation = (props: any) => {
    const styles = {
      position: 'absolute',
      zIndex: 1,
      backgroundImage:
        'linear-gradient(90deg, rgba(232, 232, 232, 0) 40%, rgba(255, 255, 255, 0.8) 60%, rgba(232, 232, 232, 0) 100%',
      backgroundSize: '300% 100%',
      width: '100%',
      maxHeight: '100%',
      ...props.style,
    }
    return <div style={styles} />
  }

  const BadgeComponent = props.badgeComponent || StaticBadge

  return (
    <>
      <ProgressiveImage placeholder={PLACEHOLDER} src={props.src} srcSet={props.srcSet} trigger='inView'>
        {(image: { src: string | undefined }, isLoading: any) => (
          <>
            <FlexBox style={{ height: '100%' }} justifyContent='center'>
              {isLoading && (
                <>
                  <AnimateKeyframes
                    play
                    iterationCount='infinite'
                    duration={2}
                    direction='reverse'
                    keyframes={['background-position: -50% 0', 'background-position: 100% 0']}
                    render={animation}
                  />
                </>
              )}
              <ResponsiveImage
                src={image.src}
                alt={props.alt}
                style={{
                  cursor: props.onClick ? 'pointer' : 'default',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
                onClick={props.onClick}
                data-testid='upload-card-image'
              />
            </FlexBox>
          </>
        )}
      </ProgressiveImage>
      {props.hasBadge && <BadgeComponent />}
    </>
  )
}

import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogFooter,
  Typography,
  Button,
  Link,
  ModalDialogCloseButton,
  ModalDialogNav,
} from '@vp/swan'
import { Project } from '../../domain/model/project'
import { useLocalization } from '../../hooks/useLocalizations'

type AddToCartConfirmationModalProps = {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
}

const AddToCartConfirmationModal = (props: AddToCartConfirmationModalProps) => {
  const { isOpen, project, onClose } = props
  const { t } = useLocalization()

  const title = t('addToCartModal.title')
  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={onClose} onlyRenderWhenOpen>
      <ModalDialogContent aria-label={title}>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t('projectDetailsModal.close')} />
        </ModalDialogNav>
        <ModalDialogHeader>{title}</ModalDialogHeader>
        <ModalDialogBody>
          <Typography>
            {project.createdInOfflineEditor ? t('addToCartModal.bodyOfflineEditor') : t('addToCartModal.body')}
          </Typography>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            {!project.createdInOfflineEditor && (
              <Button
                skin='primary'
                render={(prop) => (
                  <Link href={project.editUrl} className={prop.className}>
                    {prop.children}
                  </Link>
                )}
              >
                {t('addToCartModal.edit')}
              </Button>
            )}
            <Button onClick={onClose}>{t('addToCartModal.cancel')}</Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default AddToCartConfirmationModal

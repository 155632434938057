import { LocalStorageCacheService } from '@vp/cache-agent'
import { fetchCalloutData } from '../../clients/vpmerchant.proxy'

export const getDesignableProductStatus = async (mpvId: string, locale: string, authorizationHeader: string) => {
  const localStorageCache = new LocalStorageCacheService<string>(60)
  const localStorageKey = `vp-callOutData-${mpvId}-${locale}`

  return await localStorageCache.getData(localStorageKey, async (): Promise<string> => {
    const result = await fetchCalloutData(mpvId, locale, authorizationHeader)

    if (result) {
      const standardDesign = result?.serviceConfig?.Standard
      if (standardDesign) {
        return standardDesign.status
      }
    }

    return ''
  })
}

import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { PaginatedProjectsList } from '../domain/model/paginatedProjectsList'
import { Project } from '../domain/model/project'
import { useAlbelliProjects } from '../hooks/useAlbelliProjects'
import { useWesProjects } from '../hooks/useWesProjects'
import { AlertContext } from './AlertContext'
import { useLocalization } from '../hooks/useLocalizations'

type ProjectListContextProps = {
  projectsList: PaginatedProjectsList<Project>;
  isLoadingFirstPage: boolean;
  hasFinishedLoading: boolean;
  noProjectsError: boolean;
  refresh: () => void;
}

export const ProjectListContext = React.createContext<ProjectListContextProps>({
  projectsList: new PaginatedProjectsList(),
  isLoadingFirstPage: false,
  hasFinishedLoading: false,
  noProjectsError: false,
  refresh: () => {},
})

export const ProjectListContextProvider = (props: { children: any }) => {
  const { wesProjects, firstPageLoaded, allWesProjectsLoaded, refreshWes, wesError } = useWesProjects()
  const { albelliProjects, allAlbelliProjectsLoaded, refreshAlbelli, albelliError } = useAlbelliProjects()
  const { showAlert } = useContext(AlertContext)
  const { t } = useLocalization()

  useEffect(() => {
    if (wesError || albelliError) {
      showAlert(t('errorLoadingProjects'))
    }
  }, [wesError, albelliError])

  const [projectsList, setProjectsList] = useState<PaginatedProjectsList<Project>>(
    new PaginatedProjectsList<Project>()
  )

  const refresh = () => {
    refreshWes()
    refreshAlbelli()
  }

  const hasFinishedLoading = firstPageLoaded && allWesProjectsLoaded && allAlbelliProjectsLoaded

  const noProjectsError = wesError && albelliError

  useEffect(() => {
    if (!firstPageLoaded) return
    const newProjectList = new PaginatedProjectsList<Project>().add([...wesProjects, ...albelliProjects])

    setProjectsList(newProjectList)
  }, [firstPageLoaded, allWesProjectsLoaded, allAlbelliProjectsLoaded])

  return (
    <ProjectListContext.Provider
      value={{
        projectsList,
        isLoadingFirstPage: !firstPageLoaded,
        hasFinishedLoading,
        noProjectsError,
        refresh,
      }}
    >
      {props.children}
    </ProjectListContext.Provider>
  )
}

import { IProject } from './iproject'

export enum LegacyProjectStatus {
  AutomaticallyImportable,
  ManualUpdateInProgress,
  ManualUpdateRequired,
}

export interface LegacyProject extends IProject {
  id: string;
  name: string;
  previewUrl: string;
  status: LegacyProjectStatus;
}

import { ErrorInfo, useState, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, Column, FlexBox, GridContainer, responsive, Row, Typography } from '@vp/swan'
import SearchBox from './search/SearchBox'
import SearchResults from './search/SearchResults'
import { scrollToTop } from '../utils/browserUtils'
import CenteredSpinner from './spinners/CenteredSpinner'
import ItemsPagination from './ItemsPagination'
import { PROJECTS_CONTAINED_ELEMENT_ID, ITEMS_PER_PAGE_OPTIONS } from '../constants'
import { PaginatedProjectsList } from '../domain/model/paginatedProjectsList'
import { Project } from '../domain/model/project'
import { trackSearchEvent } from './Analytics/trackingUtils'
import { useLocalization } from '../hooks/useLocalizations'
import { useLogger } from '@vp/ubik-context'
import { ProjectTile } from './tile/ProjectTile'

const ResponsiveFlexBox = responsive(FlexBox)

type ProjectsListProps = {
  projectsList: PaginatedProjectsList<Project>;
  hasFinishedLoading: boolean;
}

const PAGE_SIZE = 12

export const ProjectsTileList = (props: ProjectsListProps) => {
  const { projectsList, hasFinishedLoading } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [visibleProjects, setVisibleProjects] = useState<Project[]>([])
  const { t } = useLocalization()
  const logger = useLogger()

  const searchTermChanged = (filter: string) => {
    if (searchTerm === filter) return
    trackSearchEvent(filter)
    setSearchTerm(filter)
    setCurrentPageScrolling(1)
  }

  const filteredProjectsList = projectsList.filter(searchTerm)
  const isLoadingFirstTime = !hasFinishedLoading && projectsList.count() === 0
  const isLoadingWhileSearching =
    !hasFinishedLoading && !!searchTerm && filteredProjectsList.getPage(PAGE_SIZE, currentPage).length === 0

  const setCurrentPageScrolling = (page: number) => {
    setCurrentPage(Number(page))
    scrollToTop(0)
  }

  useEffect(() => {
    setCurrentPageScrolling(1)
  }, [projectsList])

  const onProjectTileRenderError = (error: Error, errorInfo: ErrorInfo, project: Project) => {
    logger.error('failed to render project tile', error, { errorInfo, project })
  }

  const onVisibleItemsChangedHandler = (items: Project[]) => setVisibleProjects(items)

  return (
    <Box id={PROJECTS_CONTAINED_ELEMENT_ID}>
      <FlexBox justifyContent='flex-end'>
        <Typography fontSkin='title-display' mb='4'>
          {t('projectsList.count', { numberOfProjects: filteredProjectsList.count().toString() })}
        </Typography>
      </FlexBox>
      <ResponsiveFlexBox
        my='3'
        alignItems='center'
        justifyContent='space-between'
        xs={{ flexDirection: 'column' }}
        sm={{ flexDirection: 'row' }}
      >
        <Box style={{ width: '100%', marginBottom: '30px' }}>
          <SearchBox onSearchTermChanged={searchTermChanged} />
        </Box>
        <ItemsPagination
          items={filteredProjectsList.toArray()}
          itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
          onVisibleItemsChanged={onVisibleItemsChangedHandler}
          parentId={PROJECTS_CONTAINED_ELEMENT_ID}
        />
      </ResponsiveFlexBox>
      {searchTerm && (
        <SearchResults
          searchTerm={searchTerm}
          isLoading={isLoadingWhileSearching}
          filteredProjectsCount={filteredProjectsList.count()}
        />
      )}
      {(isLoadingFirstTime || isLoadingWhileSearching) && (
        <Box style={{ minHeight: '250px' }}>
          <CenteredSpinner />
        </Box>
      )}
      <GridContainer data-testid='project-tile-grid-container' paddingTop='4'>
        <Row>
          {visibleProjects.map((project: Project) => (
            <ErrorBoundary key={project.id} fallback={<></>} onError={(error, info) => onProjectTileRenderError(error, info, project)}>
              <Column span={4} spanXs={6} spanSm={6} spanXl={3} key={project.id}>
                <ProjectTile project={project} />
              </Column>
            </ErrorBoundary>
          ))}
        </Row>
      </GridContainer>
      <FlexBox justifyContent='center' my='3'>
        <ItemsPagination
          items={filteredProjectsList.toArray()}
          itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
          onVisibleItemsChanged={onVisibleItemsChangedHandler}
          parentId={PROJECTS_CONTAINED_ELEMENT_ID}
        />
      </FlexBox>
    </Box>
  )
}

import { useLocalStorage } from '@vp/cache-agent'
import { Project } from '..//domain/model/project'
import { fetchProductStatus } from '../clients/productStatus.proxy'
import { useUserContext, useIdentity } from '@vp/ubik-context'

const BASE_KEY = 'vp-product-status'

const useProjectHasAddToCart = (project: Project) => {
  const { locale, tenant } = useUserContext()
  const { identity } = useIdentity()
  const localStorageKey = `${BASE_KEY}-${tenant}-${locale}-${project.productKey}-${project.productVersion}`

  const productStatus = useLocalStorage(localStorageKey, () => {
    if (!project.shouldCheckProductStatus) {
      throw new Error('Project is not orderable')
    }
    if (project.productVersion === undefined) {
      throw new Error('Product version is required')
    }

    return fetchProductStatus(project.productKey, project.productVersion, tenant as string, locale, identity?.authorizationHeader as string)
  })

  if (!project.shouldCheckProductStatus || productStatus === 'ACTIVE' || productStatus === 'CURRENT') {
    return project.canBeAddedToCart() && project.isMerchandisingAvailable
  }

  return false
}

export { useProjectHasAddToCart }

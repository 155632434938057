import { useEffect, useState } from 'react'

function useOwner () {
  const [owner, setOwner] = useState<string>('')

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const isCareAgent = searchParams.get('isCareAgent') === 'true'
    const newOwner = isCareAgent ? searchParams.get('ownerId') : ''

    setOwner(newOwner || '')
  }, [])

  return owner
}

export { useOwner }

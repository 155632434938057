import NameInputModal from '../../NameInputModal'
import { Project } from '../../../domain/model/project'
import { useLocalization } from '../../../hooks/useLocalizations'

type CopyProjectModalProps = {
  project: Project;
  isOpen: boolean;
  onClose: () => void;
  onCopy: (newName: string) => Promise<void>;
}

export const CopyProjectModal = ({ project, isOpen, onClose, onCopy }: CopyProjectModalProps) => {
  const { t } = useLocalization()

  const copyNamePlaceholder = t('nameInputModal.copy.placeholder', { projectName: project.name })

  return (
    <NameInputModal
      title={t('nameInputModal.copy.title', { projectName: project.name })}
      submitButtonLabel={t('nameInputModal.copy.submit')}
      isOpen={isOpen}
      errorMessage={t('nameInputModal.copy.error')}
      placeholder={copyNamePlaceholder}
      onSubmit={onCopy}
      onClose={onClose}
    />
  )
}

import { getTracking } from '@vp/tracking'
import { Project } from '../../domain/model/project'
import { TrackingEventLabels } from '../../constants'
import { DesignHelpAction } from '@vp/dst-my-project-placement'
import { LegacyProject } from '../../domain/model/legacyProject'

const trackEvent = (label: TrackingEventLabels, eventDetails?: string) => {
  const tracking = getTracking()

  if (!tracking) {
    console.info('Tracking is not available')
    return
  }

  tracking.track(
    'My Projects Clicked',
    {
      route: 'My Account',
      category: 'My Account',
      label,
      eventDetails,
    },
    {}
  )
}

export const getDesignHelpEventLabel = (
  designHelpAction: DesignHelpAction,
  labels: {
    DESIGN_HELP: TrackingEventLabels;
    REQUEST_A_REVISION: TrackingEventLabels;
    VIEW_DESIGN: TrackingEventLabels;
  }
) => {
  switch (designHelpAction) {
    case DesignHelpAction.DESIGN_HELP:
      return labels.DESIGN_HELP
    case DesignHelpAction.REQUEST_REVISION:
      return labels.REQUEST_A_REVISION
    case DesignHelpAction.VIEW_DESIGN:
      return labels.VIEW_DESIGN
    default:
      return labels.DESIGN_HELP
  }
}

const trackProjectEvent = (label: TrackingEventLabels, project: Pick<Project, 'id' | 'trackingKey'>) => {
  const eventDetails = `projectId: ${project.id}, projectType: ${project.trackingKey}`
  trackEvent(label, eventDetails)
}

const trackLegacyProjectEvent = (label: TrackingEventLabels, project: LegacyProject) => {
  const eventDetails = `projectId: ${project.id}`
  trackEvent(label, eventDetails)
}

const trackSearchEvent = (searchTerm: string) => {
  const eventDetails = `searchTerm: ${searchTerm}`
  trackEvent(TrackingEventLabels.SEARCH, eventDetails)
}

const trackEventTiming = async (label: TrackingEventLabels, callback: () => Promise<void>) => {
  const tracking = getTracking()

  const start = new Date()
  await callback()
  const end = new Date()

  tracking?.track(
    'My Projects Event Timing',
    {
      route: 'My Account',
      category: 'My Account',
      label,
      eventDuration: end.getTime() - start.getTime(),
    },
    {}
  )
}

export { trackProjectEvent, trackLegacyProjectEvent, trackSearchEvent, trackEventTiming }

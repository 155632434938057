import { WesProject } from './wesProject'

export class VistaCreateProject extends WesProject {
  constructor (project: any, isProductAvailable: boolean, isCareAgent: boolean = false) {
    super(project, isProductAvailable, isCareAgent)

    this.hasCopy = false
    this.trackingKey = 'vistaCreate'
  }
}

import { Project as DesignServicesProject } from '@vp/dst-my-project-placement'
import { IProject } from './iproject'

export enum AddToCartOperation {
  None,
  AddToCart,
  OpenStudioModal,
}

export abstract class Project implements IProject {
  abstract id: string
  abstract name: string
  abstract created: Date
  abstract modified: Date
  abstract editUrl: string
  abstract addToCartOperation: AddToCartOperation
  abstract createdInOfflineEditor: boolean
  abstract quantity: number
  abstract productKey: string
  abstract options: { [key: string]: string }
  abstract trackingKey: string
  abstract tenant: string
  productName: string
  workRevisionId: string
  productVersion: number | undefined
  addToCartUrl: string
  isMerchandisingAvailable: boolean
  canReorder: boolean
  isFinished: boolean
  canBeDeleted: boolean

  abstract shouldCheckProductStatus: boolean

  private _imageUrl!: string
  get imageUrl (): string {
    return getHttpsUrl(this._imageUrl)
  }

  protected set imageUrl (value: string) {
    this._imageUrl = value
  }

  canBeAddedToCart = () => this.addToCartOperation !== AddToCartOperation.None

  constructor (project: any, isProductAvailable: boolean) {
    this.productName = project.productName
    this.workRevisionId = project.workRevisionId
    this.productVersion = project?.product?.version
    this.imageUrl = project?.previewImage?.imageUrl || project.design?.metadata?.previewUrl
    this.addToCartUrl = project?.resources?.addToCartUrl
    this.isMerchandisingAvailable = isProductAvailable
    this.canReorder = true
    this.isFinished = false
    this.canBeDeleted = true
  }

  public toDesignServicesProject = (): DesignServicesProject => ({
    tenant: this.tenant,
    workId: this.id,
    workRevisionId: this.workRevisionId,
    design: {
      editUrl: this.editUrl,
    },
    merchandising: {
      merchandisingSelections: this.options,
    },
  })

  abstract hasCopy: boolean
  abstract canRename: boolean
}

export const getHttpsUrl = (url: string) => {
  if (url?.startsWith('http://')) {
    return url.replace('http://', 'https://')
  }

  return url
}

import { Typography, useScreenClass } from '@vp/swan'
import { useRelativeTime } from '../../hooks/useRelativeTime'
import { Project } from '../../domain/model/project'
import { useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../hooks/useLocalizations'

type KeyProjectDatesProps = {
  project: Project;
}

export const KeyProjectsDates = ({ project }: KeyProjectDatesProps) => {
  const { locale } = useUserContext()
  const { t } = useLocalization()

  const screenClass = useScreenClass()

  const lastEditedDateRelativeDate = useRelativeTime(project.modified)

  let createdAtDate: string | null = null

  if (project.created) {
    createdAtDate = t('projectInfo.projectDateInfoCreated', {
      createdDate: project.created.toLocaleDateString(locale),
    })
  }

  let lastEditedDate: string | null = null

  if (lastEditedDateRelativeDate && !project.isFinished) {
    lastEditedDate = t('projectInfo.projectDateInfoEdited', {
      modifiedDate: lastEditedDateRelativeDate,
    })
  }

  const isSmallScreen = screenClass === 'xs'

  return (
    <Typography fontSkin='body-small' textColor='subtle'>
      {isSmallScreen && (
        <>
          {createdAtDate && <Typography>{createdAtDate}</Typography>}
          {lastEditedDate && <Typography>{lastEditedDate}</Typography>}
        </>
      )}
      {!isSmallScreen && (
        <Typography>
          {createdAtDate && createdAtDate}
          {createdAtDate && lastEditedDate && ' • '}
          {lastEditedDate && lastEditedDate}
        </Typography>
      )}
    </Typography>
  )
}

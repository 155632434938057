import { Button } from '@vp/swan'
import { AddToCartButton } from './AddToCartButton'
import { Project } from '../../domain/model/project'

type AddToCartButtonWrapperProps = {
  project: Project;
  onError: (error: any) => void;
  children: React.ReactNode;
  width?: 'standard' | 'full-width';
}

// This wrapper is to avoid unnecessary api calls as the button is disabled when the project can't be reordered
export const AddToCartButtonWrapper = ({ project, children, onError, width }: AddToCartButtonWrapperProps) => {
  const buttonProps = {
    skin: 'secondary',
    width,
  } as const

  return project.canReorder
    ? (
      <AddToCartButton project={project} onError={onError} buttonProps={buttonProps}>
        {children}
      </AddToCartButton>
      )
    : (
      <Button disabled {...buttonProps}>
        {children}
      </Button>
      )
}

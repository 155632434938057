import {
  Typography,
  ModalDialogContent,
  ModalDialog,
  ModalDialogBody,
  ModalDialogHeader,
  Button,
  ModalDialogButtons,
  ModalDialogFooter,
  ModalDialogCloseButton,
  ModalDialogNav,
} from '@vp/swan'
import { useState } from 'react'
import SpinnerButton from '../SpinnerButton'
import { useLocalization } from '../../hooks/useLocalizations'

type DeleteConfirmationModalProps = {
  isOpen: boolean;
  onDelete: () => Promise<void>;
  onClose: () => void;
}

const DeleteConfirmationModal = (props: DeleteConfirmationModalProps) => {
  const { isOpen, onDelete, onClose } = props
  const [error, setError] = useState(false)
  const { t } = useLocalization()

  const handleDelete = async () => {
    try {
      await onDelete()
      setError(false)
    } catch {
      setError(true)
    }
  }

  const handleClose = () => {
    setError(false)
    onClose()
  }

  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={handleClose} onlyRenderWhenOpen>
      <ModalDialogContent aria-label={t('deleteConfirmationModal.title')}>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t('projectDetailsModal.close')} />
        </ModalDialogNav>
        <ModalDialogHeader>{t('deleteConfirmationModal.title')}</ModalDialogHeader>
        <ModalDialogBody>
          <Typography>{t('deleteConfirmationModal.body')}</Typography>
          {error && (
            <Typography fontSkin='body-small' textColor='error'>
              {t('deleteConfirmationModal.error')}
            </Typography>
          )}
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <SpinnerButton skin='primary' onClick={handleDelete}>
              {t('deleteConfirmationModal.deleteButton')}
            </SpinnerButton>
            <Button skin='secondary' onClick={handleClose}>
              {t('deleteConfirmationModal.cancelButton')}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default DeleteConfirmationModal

import { Button, Icon } from '@vp/swan'
import { useContext, useState } from 'react'
import { copyProject } from '../../domain/commands/copyCommand'
import NameInputModal from '../NameInputModal'
import { Project } from '../../domain/model/project'
import { ProjectListContext } from '../../context/ProjectListContext'
import { useOwner } from '../../hooks/useOwner'
import { useLocalization } from '../../hooks/useLocalizations'
import { trackEventTiming, trackProjectEvent } from '../Analytics/trackingUtils'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { TrackingEventLabels } from '../../constants'

type CopyButtonProps = {
  project: Project;
}

const CopyButton = (props: CopyButtonProps) => {
  const { project } = props
  const { refresh } = useContext(ProjectListContext)
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const owner = useOwner()
  const { t } = useLocalization()
  const { tenant } = useUserContext()
  const logger = useLogger()
  const { identity } = useIdentity()
  const copyNamePlaceholder = t('nameInputModal.copy.placeholder', { projectName: project.name })

  const copyProjectAndRefresh = async (newName: string) => {
    trackProjectEvent(TrackingEventLabels.COPY, project)
    await trackEventTiming(TrackingEventLabels.COPY, () =>
      copyProject(project, newName || copyNamePlaceholder, tenant as string, owner, logger, identity?.authorizationHeader as string)
    )
    refresh()
  }

  return (
    <>
      <NameInputModal
        title={t('nameInputModal.copy.title', { projectName: project.name })}
        submitButtonLabel={t('nameInputModal.copy.submit')}
        isOpen={isCopyModalOpen}
        errorMessage={t('nameInputModal.copy.error')}
        placeholder={copyNamePlaceholder}
        onSubmit={copyProjectAndRefresh}
        onClose={() => setIsCopyModalOpen(false)}
      />

      <Button
        iconPosition='left'
        skin='tertiary'
        data-testid='copy-button'
        onClick={() => {
          setIsCopyModalOpen(true)
        }}
      >
        <Icon iconType='saveAsCopy' size='20p' mr='3' />
        {t('actionButtons.copy')}
      </Button>
    </>
  )
}

export default CopyButton

import { Button, Icon } from '@vp/swan'
import { useContext, useState } from 'react'
import { deleteProject } from '../../domain/commands/deleteCommand'
import { LogoMakerProject } from '../../domain/model/logoMakerProject'
import { Project } from '../../domain/model/project'
import { trackProjectEvent } from '../Analytics/trackingUtils'
import { AlertContext } from '../../context/AlertContext'
import { ProjectListContext } from '../../context/ProjectListContext'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import { useLocalization } from '../../hooks/useLocalizations'
import { LOGO_STATUS, TrackingEventLabels } from '../../constants'
import { useIdentity, useLogger } from '@vp/ubik-context'

const DeleteButton = (props: { project: Project }) => {
  const { refresh } = useContext(ProjectListContext)
  const logger = useLogger()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useLocalization()
  const { identity } = useIdentity()
  const { showAlert } = useContext(AlertContext)

  const isDeleteDisabled =
    !props.project.canBeDeleted ||
    (props.project instanceof LogoMakerProject && props.project.logoStatus === LOGO_STATUS.LOGO_ADDED_TO_CART)

  const showTitle = !props.project.canBeDeleted

  const deleteProjectAndRefresh = async () => {
    try {
      trackProjectEvent(TrackingEventLabels.DELETE, props.project)
      await deleteProject(props.project, identity?.authorizationHeader as string, logger)
      setIsModalOpen(false)
      refresh()
    } catch (error) {
      logger.error('Error deleting project', error as Error, { project: props.project })
      setIsModalOpen(false)
      showAlert(t('deleteConfirmationModal.error'))
    }
  }

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onDelete={() => deleteProjectAndRefresh()}
        onClose={() => setIsModalOpen(false)}
      />
      <Button
        iconPosition='left'
        disabled={isDeleteDisabled}
        skin='tertiary'
        onClick={() => setIsModalOpen(true)}
        data-testid='delete-button'
        style={isDeleteDisabled ? { opacity: '0.4', cursor: 'not-allowed' } : { cursor: 'pointer' }}
        title={showTitle ? t('tooltips.deleteButtonDisabled') : ''}
      >
        <Icon iconType='delete' size='20p' mr='3' />
        {t('actionButtons.delete')}
      </Button>
    </>
  )
}

export default DeleteButton

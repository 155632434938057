import { Spinner, Button, responsive } from '@vp/swan'
import { useState } from 'react'
import { useLocalization } from '../hooks/useLocalizations'

export type SpinnerButtonProps = {
  onClick: () => Promise<void>;
  children: any;
  skin?: 'primary' | 'secondary';
  'data-testid'?: string;
  disabled?: boolean;
  width?: 'standard' | 'full-width';
}

const SpinnerButton = (props: SpinnerButtonProps) => {
  const { onClick, children, skin, disabled, width } = props
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useLocalization()

  const ResponsiveButton = responsive(Button)

  const handleOnClick = async () => {
    setIsLoading(true)

    try {
      await onClick()
    } catch (e) {
      setIsLoading(false)
      throw e
    }

    setIsLoading(false)
  }

  return (
    <ResponsiveButton
      data-testid={props['data-testid']}
      skin={skin}
      disabled={isLoading || disabled}
      onClick={handleOnClick}
      width={width || 'standard'}
    >
      {isLoading && <Spinner accessibleText={t('loading.preloaderLabel')} size='tiny' />}
      {children}
    </ResponsiveButton>
  )
}

export default SpinnerButton

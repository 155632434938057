import { MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT_VARIANTS } from '../constants'

export const isButtonsExperienceControlVariation = (buttonsExperienceVariation?: string): boolean => {
  const isVariationV1 = isButtonsExperienceV1Variation(buttonsExperienceVariation)
  const isVariationV2 = isButtonsExperienceV2Variation(buttonsExperienceVariation)
  return !isVariationV1 && !isVariationV2
}

export const isButtonsExperienceV1Variation = (buttonsExperienceVariation?: string): boolean => {
  return buttonsExperienceVariation === MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT_VARIANTS.V1
}

export const isButtonsExperienceV2Variation = (buttonsExperienceVariation?: string): boolean => {
  return buttonsExperienceVariation === MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT_VARIANTS.V2
}

import { QtyStartingAtPrice } from '@vp/vp-tokenized-fragment'
import { useEffect } from 'react'
import { useState } from 'react'
import { getPrice, PricingServiceResponse } from '../clients/pricing.proxy'
import { Box } from '@vp/swan'
import { isButtonsExperienceControlVariation } from '../utils/buttonsExperienceVariations'
import { Project } from '../domain/model/project'
import { useLogger, useUserContext } from '@vp/ubik-context'
import { getPcxtV3, executeAfterPricingContextEvent } from '@vp/ubik-pricing-context'
import pricingContextModuleHelpers from '@vp/pricing-context-module/helpers'

interface PriceProps {
  project: Project;
  buttonsExperienceVariation?: string;
}

const Price = (props: PriceProps) => {
  const { project, buttonsExperienceVariation } = props
  const { locale } = useUserContext()

  const logger = useLogger()

  const isControl = isButtonsExperienceControlVariation(
    buttonsExperienceVariation
  )
  const [price, setPrice] = useState<PricingServiceResponse | undefined>()
  const [isVatInc, setIsVatInc] = useState(false)

  useEffect(() => {
    const PcxtV3 = getPcxtV3()

    if (!PcxtV3) {
      return
    }

    setIsVatInc(Boolean(PcxtV3.isVatInclusive()))

    executeAfterPricingContextEvent(pricingContextModuleHelpers.EVENTS.VAT_INCLUSIVITY_CHANGE_EVENT, () => {
      setIsVatInc(Boolean(PcxtV3.isVatInclusive()))
    }, true)

    let isMounted = true

    getPrice(
      project.productKey,
      project.quantity,
      project.options,
      PcxtV3.getContextAsQueryString(false),
      project.productVersion
    )
      .then((newPrice: PricingServiceResponse) => {
        if (isMounted) {
          setPrice(newPrice)
        }
      })
      .catch((error: any) => {
        logger.error('failed to get project price', error as Error, {
          project,
        })
      })

    return () => {
      isMounted = false
    }
  }, [project])

  if (!price) return null
  const listPrice = isVatInc ? price.listPrice.taxed : price.listPrice.untaxed
  const discountPrice = isVatInc
    ? price.discountedPrice.taxed
    : price.discountedPrice.untaxed

  return (
    <Box mb={isControl ? '0' : '4'}>
      <QtyStartingAtPrice
        pricingInfo={{
          quantity: project.quantity,
          listPrice,
          discountPrice,
          culture: locale,
          currency: price.currency,
          minimumFractionDigits: price.fractionDigits,
          vatInc: isVatInc,
          hideVatMessage: false,
        }}
        placeHolderText='A placeholder text'
      />
    </Box>
  )
}
export default Price

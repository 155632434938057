import { Box, FlexBox, Typography } from '@vp/swan'
import { NoProjectsIcon } from '../../icons/icon-noProjects'
import NoProjectsRecommendations from './NoProjectsRecommendations'
import { useLocalization } from '../../hooks/useLocalizations'

const NoResponse = () => {
  const { t } = useLocalization()

  return (
    <FlexBox marginY='6' style={{ width: '100%' }} flexDirection='column' alignItems='center'>
      <Box style={{ height: '200px', width: '200px' }}>
        <NoProjectsIcon />
      </Box>
      <Typography mt='4' fontSkin='body-standard-bold'>
        {t('errorLoadingProjectsMsg')}
      </Typography>
      <NoProjectsRecommendations />
    </FlexBox>
  )
}

export default NoResponse

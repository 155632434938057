import { useEffect, useState } from 'react'
import { Project } from '../domain/model/project'
import { getAlbelliProjects } from '../domain/services/albelliProjectService'
import { useIdentity, useUserContext, useLogger } from '@vp/ubik-context'
import { useOwner } from './useOwner'
import { DEFAULT_TENANT } from '../constants'

const useAlbelliProjects = () => {
  const [albelliProjects, setAlbelliProjects] = useState<Project[]>([])
  const [isRefreshing, setIsRefreshing] = useState(true)
  const [albelliError, setAlbelliError] = useState(false)
  const { isIdentityInitialized, identity } = useIdentity()
  const { locale, tenant: contextTenant } = useUserContext()
  const tenant = contextTenant || DEFAULT_TENANT
  const owner = useOwner()
  const logger = useLogger()

  const refreshAlbelli = () => {
    setAlbelliProjects([])
    setIsRefreshing(true)
  }

  useEffect(() => {
    let isMounted = true

    if (!isIdentityInitialized || !isRefreshing) return

    const getAlbelliProjectsFromService = async () => {
      let albelliProjects: Project[] = []
      try {
        albelliProjects = await getAlbelliProjects({ owner, tenant, locale, authorizationHeader: identity?.authorizationHeader as string, logger })
      } catch (error) {
        logger.error('Failed to get albelli projects', error as Error)
        setAlbelliError(true)
      }

      if (isMounted) {
        setAlbelliProjects(albelliProjects)
        setIsRefreshing(false)
      }
    }

    getAlbelliProjectsFromService()

    return () => {
      isMounted = false
    }
  }, [isIdentityInitialized, owner, isRefreshing])

  return { albelliProjects, allAlbelliProjectsLoaded: !isRefreshing, refreshAlbelli, albelliError }
}

export { useAlbelliProjects }

import { ReactNode, useContext } from 'react'
import {
  FlexBox,
  ModalDialog,
  ModalDialogContent,
  ModalDialogHeader,
  Typography,
  ModalDialogBody,
  useScreenClass,
  ModalDialogFooter,
  ModalDialogCloseButton,
  GridContainer,
  Row,
  Column,
  Divider,
  Box,
  tokens,
  ModalDialogNav,
} from '@vp/swan'
import { Project } from '../../domain/model/project'
import { EditButton } from '../actionButtons/Edit'
import { trackProjectEvent } from '../Analytics/trackingUtils'
import { useTranslatedProductOptions } from '../../hooks/useTranslatedProductOptions'
import { CarouselProductImage } from './images/CarouselProjectImage'
import { AddToCartButtonWrapper } from '../addToCart/AddToCartButtonWrapper'
import { AlertContext } from '../../context/AlertContext'
import { TrackingContextProvider, useTracking } from '../../context/TrackingContext'
import { ProjectTileDetailsModalActionMenu } from './actionMenu/ProjectTileDetailsModalActionMenu'
import { DesignServicesCTABanner } from './components/DesignServicesCTABanner'
import { OutOfStockCallout } from './components/Callouts/OutOfStockCallout'
import { KeyProjectsDates } from './KeyProjectDates'
import Price from '../Price'
import { useLocalization } from '../../hooks/useLocalizations'
import { TrackingEventLabels } from '../../constants'

type ProjectTileDetailsModalProps = {
  project: Project;
  isOpen: boolean;
  onClose: () => void;
}

export const ProjectTileDetailsModal = ({ project, isOpen, onClose }: ProjectTileDetailsModalProps) => {
  const screenClass = useScreenClass()
  const { t } = useLocalization()
  const { showAlert } = useContext(AlertContext)

  const isSmallScreen = screenClass === 'xs'
  const isOutOfStock = project.canReorder && !project.isMerchandisingAvailable

  return (
    <TrackingContextProvider
      labels={{
        ADD_TO_CART: TrackingEventLabels.PROJECT_DETAILS_MODAL_ADD_TO_CART,
        DELETE: TrackingEventLabels.PROJECT_DETAILS_MODAL_DELETE,
        EDIT: TrackingEventLabels.PROJECT_DETAILS_MODAL_EDIT,
        RENAME: TrackingEventLabels.PROJECT_DETAILS_MODAL_RENAME,
        COPY: TrackingEventLabels.PROJECT_DETAILS_MODAL_COPY,
        DESIGN_HELP: TrackingEventLabels.PROJECT_DETAILS_MODAL_DESIGN_HELP,
        REQUEST_A_REVISION: TrackingEventLabels.PROJECT_DETAILS_MODAL_REQUEST_A_REVISION,
        VIEW_DESIGN: TrackingEventLabels.PROJECT_DETAILS_MODAL_VIEW_DESIGN,
      }}
    >
      <ModalDialog
        data-testid='project-tile-details-modal'
        isOpen={isOpen}
        onRequestDismiss={onClose}
        variant='panel-right'
        bodyWidth='grow'
        onlyRenderWhenOpen
      >
        <ModalDialogContent aria-label={project.name} fullBleed style={{ width: '1000px', maxWidth: '100%' }}>
          <ModalDialogNav>
            <ModalDialogCloseButton accessibleText={t('projectDetailsModal.close')} />
          </ModalDialogNav>
          <ModalDialogHeader paddingTop='7'>
            <Padded>
              <FlexBox
                justifyContent='space-between'
                gap='4'
                paddingRight='6'
                alignItems={isSmallScreen ? undefined : 'center'}
              >
                <FlexBox style={{ minWidth: '0px' }} flexDirection='column'>
                  <ProjectTileDetailsModalStatusStack>
                    {isOutOfStock && <OutOfStockCallout />}
                  </ProjectTileDetailsModalStatusStack>
                  <Typography pb='3' truncate>
                    {project.name}
                  </Typography>
                  <FlexBox
                    alignItems={isSmallScreen ? 'baseline' : 'end'}
                    gap='5'
                    flexWrap='wrap'
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                  >
                    <KeyProjectsDates project={project} />
                  </FlexBox>
                </FlexBox>

                <FlexBox gap='2' flexDirection='row' alignItems='flex-start'>
                  {!isSmallScreen && (
                    <>
                      <TrackedEditButton project={project} />
                      <FlexBox flexDirection='column' textAlign='center' gap='2' style={{ maxWidth: 'min-content' }}>
                        <AddToCartButtonWrapper project={project} onError={() => showAlert(t('addToCartError'))}>
                          {t('actionButtons.addToCart')}
                        </AddToCartButtonWrapper>
                        {project.isMerchandisingAvailable && project.canReorder && (
                          <Box style={{ minHeight: '40px' }}>
                            <Typography fontSkin='body-small'>
                              <Price project={project} />
                            </Typography>
                          </Box>
                        )}
                      </FlexBox>
                    </>
                  )}
                  <ProjectTileDetailsModalActionMenu project={project} />
                </FlexBox>
              </FlexBox>
            </Padded>
          </ModalDialogHeader>

          <ModalDialogBody>
            <GridContainer>
              <Row
                style={{
                  borderTop: `1px solid ${tokens.SwanBaseColorGrey300}`,
                  borderBottom: `1px solid ${tokens.SwanBaseColorGrey300}`,
                }}
              >
                <Column span={7} padding='6' flexColumn backgroundColor='strong' style={{ alignItems: 'center' }}>
                  <Box paddingLeft='3' style={{ width: '80%' }}>
                    <CarouselProductImage project={project} />
                  </Box>
                </Column>
                <Column span={5} padding={0}>
                  <DesignServicesCTABanner project={project} />
                  <Box padding='6'>
                    <ProductDetails project={project} />
                  </Box>
                </Column>
              </Row>
            </GridContainer>
          </ModalDialogBody>

          {isSmallScreen && (
            <ModalDialogFooter pinned>
              <FlexBox justifyContent='center' gap='2' alignItems='flex-start'>
                <TrackedEditButton project={project} />
                <FlexBox flexDirection='column' textAlign='center' style={{ maxWidth: 'min-content' }}>
                  <AddToCartButtonWrapper project={project} onError={() => showAlert(t('addToCartError'))}>
                    {t('actionButtons.addToCart')}
                  </AddToCartButtonWrapper>
                  {project.isMerchandisingAvailable && project.canReorder && (
                    <Box style={{ minHeight: '40px' }}>
                      <Typography fontSkin='body-small'>
                        <Price project={project} />
                      </Typography>
                    </Box>
                  )}
                </FlexBox>
              </FlexBox>
            </ModalDialogFooter>
          )}
        </ModalDialogContent>
      </ModalDialog>
    </TrackingContextProvider>
  )
}

const Padded = ({ children }: { children: ReactNode }) => {
  return (
    <Box paddingLeft='7' paddingRight='7'>
      {children}
    </Box>
  )
}

const ProductDetails = ({ project }: { project: Project }) => {
  const { t } = useLocalization()
  const translatedOptions = useTranslatedProductOptions(project)

  return (
    <Typography fontSkin='body-small'>
      <FlexBox gap='3' flexDirection='column'>
        <Typography fontSkin='body-standard-bold'>
          {t('projectDetailsModal.detailsSection.projectDetailsHeading')}
        </Typography>

        {project.productName && (
          <>
            <BoldAttributeValue
              attribute={t('projectDetailsModal.detailsSection.productNameKey')}
              value={project.productName}
            />

            <Divider />
          </>
        )}

        <BoldAttributeValue attribute={t('projectDetailsModal.detailsSection.IDKey')} value={project.id} />

        {Object.keys(translatedOptions).length > 0 && (
          <>
            <Divider />
            <Typography>
              <Typography fontSkin='body-standard-bold'>
                {t('projectDetailsModal.detailsSection.productDetailsKey')}:
              </Typography>
              <span>
                {Object.keys(translatedOptions).map((option) => (
                  <Typography key={option}>{`${option}: ${translatedOptions[option]}`}</Typography>
                ))}
              </span>
            </Typography>
          </>
        )}
      </FlexBox>
    </Typography>
  )
}

const BoldAttributeValue = ({ attribute, value }: { attribute: string; value: string }) => {
  return (
    <Typography fontSkin='body-standard-bold'>
      {attribute}:{' '}
      <Typography as='span' fontSkin='body-standard'>
        {value}
      </Typography>
    </Typography>
  )
}

const TrackedEditButton = ({ project }: { project: Project }) => {
  const { labels } = useTracking()
  const { t } = useLocalization()
  return (
    <EditButton size='mini' project={project} skin='primary' onClick={() => trackProjectEvent(labels.EDIT, project)}>
      {t('actionButtons.editDesign')}
    </EditButton>
  )
}

const ProjectTileDetailsModalStatusStack = ({ children }: { children: ReactNode }) => {
  return (
    <FlexBox gap='3' alignItems='flex-start' paddingBottom='2' flexWrap='wrap'>
      {children}
    </FlexBox>
  )
}

import { ProductOptionsTranslations } from '../domain/model/product'
import { REQUESTOR } from '../constants'
import { Logger } from '@vp/ubik-logging'

const MSX_BASE_URL = 'https://merchandising-site-experience.prod.merch.vpsvc.com/api/v1'

export const fetchOptionNamesFromServer = async function (
  tenant: string,
  locale: string,
  productKey: string,
  productVersion: string,
  logger: Logger
) {
  locale = locale.toLowerCase()
  try {
    const response = await fetch(
      `${MSX_BASE_URL}/tenant/${tenant}/productKey/${productKey}/version/${productVersion}/experienceandordering/attributesandvalues?requestor=${REQUESTOR}&cultures=${locale}&lower=true`
    )
    const result = await response.json() as any
    return result[locale] as ProductOptionsTranslations[]
  } catch (error) {
    logger.error('Failed to fetch product options translations', error as Error)
    return []
  }
}

export const fetchProductNames = async (tenant: string, locale: string, productKeys: string[], logger : Logger) => {
  try {
    const response = await fetch(
      `${MSX_BASE_URL}/tenant/${tenant}/culture/${locale}/productName?requestor=${REQUESTOR}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productKeys }),
      }
    )
    const result = await response.json()
    return result as Record<string, string>
  } catch (error) {
    logger.error('Failed to fetch product names', error as Error)
    return {}
  }
}

export const fetchAllAvailableProducts = async (tenant: string, locale: string, logger :Logger) => {
  try {
    const response = await fetch(
      `${MSX_BASE_URL}/tenant/${tenant}/culture/${locale}/availability?availabilities=Public&availabilities=InternalTest&requestor=${REQUESTOR}`
    )
    const result = await response.json()
    return result as string[]
  } catch (error) {
    logger.error('Failed to fetch all available products', error as Error)
    return [] as string[]
  }
}

export const fetchPRDFromMPV = async (tenant: string, locale: string, mpv: string, logger : Logger) => {
  try {
    const response = await fetch(
      `${MSX_BASE_URL}/tenant/${tenant}/mpvs/${mpv}/?requestor=${REQUESTOR}&cultures=${locale}`
    )
    const result = await response.json() as Record<string, string>
    return result[locale.toLowerCase()] as string
  } catch (error) {
    logger.error('Failed to fetch product mapping', error as Error)
    return undefined
  }
}

export const fetchMpvIdFromProductKey = async (tenant: string, locale: string, productKey: string, logger : Logger) => {
  try {
    const response = await fetch(
      `${MSX_BASE_URL}/tenant/${tenant}/productKey/${productKey}/mpvs?requestor=${REQUESTOR}&cultures=${locale}`
    )
    const result = await response.json() as Record<string, string>
    return result[locale.toLowerCase()] ? (result[locale.toLowerCase()] as string) : null
  } catch (error) {
    logger.error('Failed to fetch product mpvId', error as Error)
    return undefined
  }
}

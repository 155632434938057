import { LOGO_STATUS } from '../../constants'
import { AddToCartOperation } from './project'
import { WesProject } from './wesProject'

export class LogoMakerProject extends WesProject {
  logoId: string
  logoStatus: string
  logoSource: string

  constructor (project: any, isProductAvailable: boolean, isCareAgent: boolean = false) {
    super(project, isProductAvailable)

    this.options = {}

    this.addToCartOperation = AddToCartOperation.None
    this.shouldCheckProductStatus = false

    this.trackingKey = 'logoMaker'
    this.logoId = project?.design?.metadata?.logoId
    this.imageUrl = project?.design?.displayUrl
    this.canReorder = false
    this.hasCopy = false
    this.logoStatus = project?.design?.metadata?.status || ''
    this.isFinished =
      this.logoStatus === LOGO_STATUS.LOGO_COMPLETED ||
      this.logoStatus === LOGO_STATUS.LOGO_ORDER_PROCESSING ||
      project?.design?.metadata?.paid === 'true'
    this.canBeDeleted = !this.isFinished
    this.canRename = !this.isFinished
    this.logoSource = project?.design?.metadata?.logoSource || ''
    if (isCareAgent) {
      this.editUrl = ''
    }
  }
}

import { Callout } from '@vp/swan'
import { useLocalization } from '../../../../hooks/useLocalizations'

export const OutOfStockCallout = () => {
  const { t } = useLocalization()

  return (
    <Callout variant='overlay' size='mini'>
      {t('outOfStock')}
    </Callout>
  )
}

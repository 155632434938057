import { Button, ButtonProps, Icon } from '@vp/swan'
import { LogoMakerProject } from '../../domain/model/logoMakerProject'
import { trackProjectEvent } from '../Analytics/trackingUtils'
import { TrackingEventLabels } from '../../constants'
import { Project } from '../../domain/model/project'
import { downloadLogoZipFromAPI } from '../../clients/download.proxy'
import { withPopover } from './PopoverWrapper'
import { useIdentity } from '@vp/ubik-context'
import { LOGO_STATUS } from '../../constants'
import { useLocalization } from '../../hooks/useLocalizations'

interface DownloadButtonProps extends ButtonProps {
  project: Pick<LogoMakerProject, 'logoId' | 'logoStatus'>;
}

export const DownloadButton = (props : DownloadButtonProps) => {
  const { project, ...buttonProps } = props
  const { t } = useLocalization()

  const { identity } = useIdentity()
  const isLogoOrderProcessing = project.logoStatus === LOGO_STATUS.LOGO_ORDER_PROCESSING

  const onDownloadClickHandler = async () => {
    sessionStorage.removeItem('isRedirectedFromProjectPage')
    try {
      const downloadEventDetails: Pick<Project, 'id' | 'trackingKey'> = {
        id: project.logoId,
        trackingKey: 'download Logo',
      }
      trackProjectEvent(TrackingEventLabels.DOWNLOAD_LOGO, downloadEventDetails)
      await downloadLogoZipFromAPI(project.logoId, identity?.authorizationHeader as string)
    } catch (error: any) {
      throw new Error('Error while downloading logo', error)
    }
  }

  const DownloadButton = () => (
    <Button
      {...buttonProps}
      iconPosition='left'
      skin='tertiary'
      data-testid='download-button'
      onClick={isLogoOrderProcessing ? undefined : onDownloadClickHandler}
    >
      <Icon iconType='download' size='20p' mr='3' />
      {t('actionButtons.download')}
    </Button>
  )

  if (isLogoOrderProcessing) {
    return withPopover(DownloadButton(), 'popOverContent.downloadOrEditButtonDisabled')
  }

  return DownloadButton()
}

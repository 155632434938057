import { useEffect, useState } from 'react'

function useDataAsync<T> (callback: () => Promise<T>, deps: any[]) {
  const [state, setState] = useState<T>()

  useEffect(() => {
    let canceled = false

    const fetchData = async () => {
      const data = await callback()

      if (!canceled) {
        setState(data)
      }
    }

    fetchData()

    return () => {
      canceled = true
    }
  }, deps)

  return state
}

export default useDataAsync

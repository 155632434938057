const UDS_TENANT = 'account:4HVsAccqLzE6BPbmvrDaKw'
type UdsDocument = any

const saveNewDocument = async (document: UdsDocument, authorizationHeader: string): Promise<any> => {
  const response = await fetch('https://uds.documents.cimpress.io/v3/documents', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationHeader
    },
    body: JSON.stringify(document)
  })
  if (!response.ok) {
    throw new Error(`Failed to save document: ${response.statusText}`)
  }

  return response.json() as any
}

const getDocument = async (documentRevisionUrl: string, authorizationHeader: string) => {
  const result = await fetch(documentRevisionUrl, {
    headers: {
      Authorization: authorizationHeader
    }
  })
  if (!result.ok) {
    throw new Error(`Failed to fetch document: ${result.statusText}`)
  }
  return result.json() as UdsDocument
}

export async function cloneDocument (documentRevisionUrl: string, authorizationHeader: string) {
  const document = await getDocument(documentRevisionUrl, authorizationHeader)

  document.tenant = UDS_TENANT

  const udsResponse = await saveNewDocument(document, authorizationHeader)

  return {
    url: udsResponse._links.self.href,
    documentRevisionUrl: udsResponse._links.documentRevision.href,
    instructionSourceUrl: udsResponse._links.drawingInstructions.href,
    previewInstructionSourceUrl: udsResponse._links.previewInstructions.href,
  }
}

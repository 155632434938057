import { Spinner, FlexBox } from '@vp/swan'
import { useLocalization } from '../../hooks/useLocalizations'

function CenteredSpinner () {
  const { t } = useLocalization()
  return (
    <FlexBox style={{ height: '100%' }} justifyContent='center' alignItems='center'>
      <Spinner size='super' aria-label={t('loading.preloaderLabel')} accessibleText={t('loading.preloaderLabel')} />
    </FlexBox>
  )
}

export default CenteredSpinner

import { REQUESTOR } from '../constants'
// Uncomment the following line to simulate a legacy project
// import { testJson } from '../__mockery__/testLegacy'

export async function fetchLegacyDocumentsPage (
  locale: string,
  offset: number,
  pageSize: number,
  owner: string,
  authorizationHeader: string
) {
  const url = new URL(`https://documents.design.vpsvc.com/api/v2/documents/locale/${locale.toLowerCase()}/legacy`)

  url.searchParams.set('requestor', REQUESTOR)
  url.searchParams.set('offset', offset.toString())
  url.searchParams.set('count', pageSize.toString())
  url.searchParams.set('excludeImported', 'true')

  if (owner) url.searchParams.set('owner', owner)

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Authorization: authorizationHeader,
      'Content-Type': 'application/json'
    }
  })

  if (!response.ok) {
    throw new Error('Failed to fetch legacy documents page')
  }

  // Uncomment the following line to simulate a legacy project
  // const data = testJson
  const data = await response.json() as any
  return data.documents
}

export async function importLegacyDocument (locale: string, projectId: string, authorizationHeader: string) {
  const response = await fetch(
    `https://documents.design.vpsvc.com/api/v1/documents/locale/${locale.toLowerCase()}/legacy/${projectId}?requestor=${REQUESTOR}`,
    {
      method: 'POST',
      headers: {
        Authorization: authorizationHeader,
        'Content-Type': 'application/json'
      }
    }
  )

  if (!response.ok) {
    throw new Error('Failed to import legacy document')
  }
  return true
}

import {
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogFooter,
  Button,
  Typography,
  ModalDialogCloseButton,
  ModalDialogNav,
} from '@vp/swan'
import { useLocalization } from '../../hooks/useLocalizations'

const ImportFailedModal = (props: { onSubmit: () => void; onClose: () => void; isOpen: boolean }) => {
  const { onSubmit, onClose, isOpen } = props

  const { t } = useLocalization()

  return (
    <ModalDialog onRequestDismiss={onClose} isOpen={isOpen} onlyRenderWhenOpen>
      <ModalDialogContent>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t('projectDetailsModal.close')} />
        </ModalDialogNav>
        <ModalDialogHeader>{t('legacyProjects.importFailedModal.header')}</ModalDialogHeader>
        <ModalDialogBody>
          <Typography>{t('legacyProjects.importFailedModal.body')}</Typography>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button onClick={onSubmit} skin='primary'>
              {t('legacyProjects.importFailedModal.getHelp')}
            </Button>
            <Button onClick={onClose}>{t('legacyProjects.importFailedModal.close')}</Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default ImportFailedModal

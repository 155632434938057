import 'moment/locale/da'
import 'moment/locale/de-at'
import 'moment/locale/de-ch'
import 'moment/locale/de'
import 'moment/locale/en-au'
import 'moment/locale/en-ca'
import 'moment/locale/en-gb'
import 'moment/locale/en-ie'
import 'moment/locale/en-in'
import 'moment/locale/en-nz'
import 'moment/locale/en-sg'
import 'moment/locale/es'
import 'moment/locale/es-us'
import 'moment/locale/fi'
import 'moment/locale/fr-ca'
import 'moment/locale/fr-ch'
import 'moment/locale/fr'
import 'moment/locale/it-ch'
import 'moment/locale/it'
import 'moment/locale/ja'
import 'moment/locale/nb'
import 'moment/locale/nl'
import 'moment/locale/pt'
import 'moment/locale/sv'

const momentLocaleDictionary: { [key: string]: string } = {
  'da-dk': 'da',
  'de-at': 'de-at',
  'de-ch': 'de-ch',
  'de-de': 'de',
  'en-au': 'en-au',
  'en-ca': 'en-ca',
  'en-gb': 'en-gb',
  'en-ie': 'en-ie',
  'en-in': 'en-in',
  'en-nz': 'en-nz',
  'en-sg': 'en-sg',
  'en-us': 'en',
  'es-es': 'es',
  'es-us': 'es-us',
  'fi-fi': 'fi',
  'fr-be': 'fr',
  'fr-ca': 'fr-ca',
  'fr-ch': 'fr-ch',
  'fr-fr': 'fr',
  'it-ch': 'it-ch',
  'it-it': 'it',
  'ja-jp': 'ja',
  'nb-no': 'nb',
  'nl-be': 'nl',
  'nl-nl': 'nl',
  'pt-pt': 'pt',
  'sv-se': 'sv',
}

export const mapLocaleToMomentLocale = (culture: string): string => {
  return momentLocaleDictionary[culture.toLowerCase()] || 'en'
}

import { useContext, useState } from 'react'
import { FormField, FormLabel, SearchInput } from '@vp/swan'
import { useEffectDebounced } from '../../hooks/useEffectDebounced'
import { ProjectListContext } from '../../context/ProjectListContext'
import { useLocalization } from '../../hooks/useLocalizations'

type SearchBoxProps = {
  onSearchTermChanged: (term: string) => void;
}

const SearchBox = (props: SearchBoxProps) => {
  const { onSearchTermChanged } = props
  const { hasFinishedLoading } = useContext(ProjectListContext)
  const [searchTerm, setSearchTerm] = useState('')
  const { t } = useLocalization()

  useEffectDebounced(() => onSearchTermChanged(searchTerm), 1000, [searchTerm])

  return (
    <FormField>
      <FormLabel visuallyHidden>{t('search.searchInput')}</FormLabel>
      <SearchInput
        placeholder={hasFinishedLoading ? t('search.placeholder') : t('search.placeholderLoading')}
        accessibleTextForClearButton={t('search.clear')}
        accessibleTextForSearchButton={t('search.placeholder')}
        disabled={!hasFinishedLoading}
        value={searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value)}
        onClear={() => {
          setSearchTerm('')
          onSearchTermChanged('')
        }}
      />
    </FormField>
  )
}

export default SearchBox

import { AddToCartOperation, Project } from './project'

export class WesProject extends Project {
  id: string
  name: string
  created: Date
  modified: Date
  editUrl: string
  hasCopy: boolean
  canRename: boolean
  addToCartOperation: AddToCartOperation
  createdInOfflineEditor: boolean
  quantity: number
  productKey: string
  options: Record<string, string>
  trackingKey: string
  tenant: string
  displayUrl: string

  shouldCheckProductStatus = true

  constructor (project: any, isProductAvailable: boolean, isCareAgent: boolean = false) {
    super(project, isProductAvailable)

    this.options = project?.merchandising?.merchandisingSelections
    this.id = project.workId
    this.name = project.workName || this.productName
    this.created = this.getNormalizedDate(project.created)
    this.modified = this.getNormalizedDate(project.modified)
    this.editUrl = isCareAgent ? `${project.design.editUrl}&careAgent=true` : project.design.editUrl
    this.displayUrl = project.design.displayUrl
    this.hasCopy = true
    this.canRename = true
    this.addToCartOperation = AddToCartOperation.AddToCart
    this.createdInOfflineEditor = false
    this.quantity = project.merchandising.quantity
    this.productKey = project.product.key || project.design?.metadata?.productKey || project.resources?.productKey
    this.trackingKey = 'wes'
    this.tenant = project.tenant
  }

  private getNormalizedDate (date: string) {
    let processedDate = date

    // legacy documents can have a +/-00:00 time attached to the date for time zones
    // process the date and convert it to an ISO string
    if (/[+-]\d\d:\d\d$/.test(date)) {
      processedDate = new Date(date).toISOString()
    }

    // we need the Z at the end of the date strings for displaying the correct time in the correct time zone
    if (typeof processedDate === 'string' && !/Z$/.test(processedDate)) {
      processedDate += 'Z'
    }

    return new Date(processedDate)
  }
}

import React from 'react'
import { ModalDialog, ModalDialogContent, ModalDialogCloseButton } from '@vp/swan'
import { helpCenterLink } from '../utils/helpCenterLinks'
import { RecreationModalDialogContent } from './modalComponents/RecreationModalDialogContent'
import { useLocalization } from '../hooks/useLocalizations'
import { useLogger } from '@vp/ubik-context'
interface LegacyRecreationModalProps {
  isOpen: boolean;
  onRequestDismiss: any;
  culture: string;
}
export const LegacyRecreationModal = ({ isOpen, onRequestDismiss, culture }: LegacyRecreationModalProps) => {
  const { t } = useLocalization()
  const logger = useLogger()
  React.useEffect(() => {
    if (isOpen) {
      logger.info('Legacy Recreation Modal', {
        isOpen,
        culture,
      })
    }
  }, [isOpen])

  const onSubmit = async () => {
    logger.info('Legacy Recreation Modal Submit', {
      isOpen,
      culture,
    })
    window.location.href = helpCenterLink[culture.toLowerCase()]
  }

  return (
    <ModalDialog isOpen={isOpen} onRequestDismiss={onRequestDismiss} variant='standard'>
      <ModalDialogContent
        aria-labelledby='legacy-recreation-modal-dialog-dst-title'
        style={{ width: '420px', padding: '50px' }}
        data-testid='legacy-recreation-modal'
      >
        <ModalDialogCloseButton accessibleText={t('legacyProjects.close')} />
        <RecreationModalDialogContent onSubmit={onSubmit} onRequestDismiss={onRequestDismiss} />
      </ModalDialogContent>
    </ModalDialog>
  )
}

import { useState, useEffect } from 'react'
import { Project } from '../domain/model/project'
import { PaginatedProjectsList } from '../domain/model/paginatedProjectsList'
import ProjectCard from './card/ProjectCard'
import { Box, Column, FlexBox, GridContainer, responsive, Row, Typography, useScreenClass } from '@vp/swan'

import SearchBox from './search/SearchBox'
import SearchResults from './search/SearchResults'
import { scrollToTop } from '../utils/browserUtils'
import CenteredSpinner from './spinners/CenteredSpinner'
import { trackSearchEvent } from './Analytics/trackingUtils'
import ItemsPagination from './ItemsPagination'
import { ITEMS_PER_PAGE_OPTIONS, PROJECTS_CONTAINED_ELEMENT_ID } from '../constants'
import { useLocalization } from '../hooks/useLocalizations'

const ResponsiveFlexBox = responsive(FlexBox)

type ProjectsListProps = {
  projectsList: PaginatedProjectsList<Project>;
  hasFinishedLoading: boolean;
}

export const ProjectsList = (props: ProjectsListProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [visibleProjects, setVisibleProjects] = useState<Project[]>([])
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState<number | undefined>()
  const [selectedPage, setSelectedPage] = useState<number | undefined>()
  const [filteredProjectsList, setFilteredProjectsList] = useState<Project[]>([])

  const { projectsList, hasFinishedLoading } = props
  const { t } = useLocalization()

  const searchTermChanged = (filter: string) => {
    if (searchTerm === filter) return
    trackSearchEvent(filter)
    setSearchTerm(filter)
  }

  const screenSize = useScreenClass()
  const isMobileOrTablet = screenSize === 'xs' || screenSize === 'sm'
  const searchBarMarginBottom = isMobileOrTablet ? 'between-subsections' : '0'

  useEffect(() => {
    if (!projectsList) return
    setFilteredProjectsList(projectsList.filter(searchTerm).toArray())
  }, [projectsList, searchTerm])

  const isLoadingFirstTime = !hasFinishedLoading && projectsList.count() === 0
  const isLoadingWhileSearching = !hasFinishedLoading && !!searchTerm && filteredProjectsList.length === 0

  const setCurrentPageScrolling = () => {
    scrollToTop(0)
  }

  const updateSelectedItemsPerPage = (itemsPerPage: number) => {
    setSelectedItemsPerPage(itemsPerPage)
    setCurrentPageScrolling()
    setSelectedPage(1)
  }

  useEffect(() => {
    setCurrentPageScrolling()
  }, [projectsList])

  const onVisibleItemsChangedHandler = (items: Project[]) => setVisibleProjects(items)

  return (
    <Box id={PROJECTS_CONTAINED_ELEMENT_ID}>
      <ResponsiveFlexBox justifyContent='flex-end' xs={{ mb: '3' }} sm={{ mb: 'to-actions' }}>
        <Typography fontSkin='title-subsection'>
          {t('projectsList.count', { numberOfProjects: filteredProjectsList.length.toString() })}
        </Typography>
      </ResponsiveFlexBox>
      <ResponsiveFlexBox
        alignItems='center'
        justifyContent='space-between'
        xs={{ flexDirection: 'column' }}
        md={{ flexDirection: 'row' }}
      >
        <Box mb={searchBarMarginBottom} style={{ width: '100%' }}>
          <SearchBox onSearchTermChanged={searchTermChanged} />
        </Box>
        <ItemsPagination
          items={filteredProjectsList}
          itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
          onVisibleItemsChanged={onVisibleItemsChangedHandler}
          parentId={PROJECTS_CONTAINED_ELEMENT_ID}
          selectedItemsPerPage={selectedItemsPerPage}
          onItemsPerPageChange={updateSelectedItemsPerPage}
          selectedPage={selectedPage}
          onPageChange={setSelectedPage}
        />
      </ResponsiveFlexBox>
      {searchTerm && (
        <SearchResults
          searchTerm={searchTerm}
          isLoading={isLoadingWhileSearching}
          filteredProjectsCount={filteredProjectsList.length}
        />
      )}
      {(isLoadingFirstTime || isLoadingWhileSearching) && (
        <Box style={{ minHeight: '250px' }}>
          <CenteredSpinner />
        </Box>
      )}
      <GridContainer>
        <Row>
          {visibleProjects.map((project: Project) => (
            <Column span={12} key={project.id}>
              <Box mt='between-subsections' key={project.id}>
                <ProjectCard project={project} />
              </Box>
            </Column>
          ))}
        </Row>
      </GridContainer>
      <FlexBox justifyContent='center' mt='between-subsections' mb='between-subsections'>
        <ItemsPagination
          items={filteredProjectsList}
          itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
          onVisibleItemsChanged={onVisibleItemsChangedHandler}
          parentId={PROJECTS_CONTAINED_ELEMENT_ID}
          selectedItemsPerPage={selectedItemsPerPage}
          onItemsPerPageChange={updateSelectedItemsPerPage}
          selectedPage={selectedPage}
          onPageChange={setSelectedPage}
        />
      </FlexBox>
    </Box>
  )
}

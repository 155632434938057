import { Logger } from '@vp/ubik-logging'
import { deleteAlbelliDoc, fetchAllAlbelliDocs } from '../../clients/albelli.proxy'
import { AlbelliDoc } from '../../clients/albelli.proxy'
import { AlbelliProject } from '../model/albelliProject'
import { getAllAvailableProducts, getPRDFromMPV } from './msxService'

export const getAlbelliProjects = async (params :
{
  owner: string,
  tenant: string,
  locale: string,
  authorizationHeader: string
  logger: Logger
}
): Promise<AlbelliProject[]> => {
  const { owner, tenant, locale, authorizationHeader, logger } = params
  const [albelliProjectsFromService, allAvailableProducts]: [AlbelliDoc[], string[] | undefined] = await Promise.all([
    fetchAllAlbelliDocs(tenant, owner, authorizationHeader),
    getAllAvailableProducts(tenant, locale, logger),
  ])

  return await Promise.all(
    albelliProjectsFromService.map(async (project) => {
      if (project.locale && project.locale.toLowerCase() !== locale.toLowerCase()) {
        return new AlbelliProject(project, '', false)
      }

      const productKey = (await getPRDFromMPV(tenant, locale, project.mpv, logger)) || ''

      const productAvailability = allAvailableProducts?.some((x) => x === productKey) ?? false

      return new AlbelliProject(project, productKey, productAvailability)
    })
  )
}

export const deleteProject = async (projectId: string, authorizationHeader: string): Promise<void> => {
  await deleteAlbelliDoc(projectId, authorizationHeader)
}

import { AddToCartOperation, Project } from './project'

export class AlbelliProject extends Project {
  id: string
  name: string
  created: Date
  modified: Date
  editUrl: string
  hasCopy: boolean
  canRename: boolean
  addToCartOperation: AddToCartOperation
  createdInOfflineEditor: boolean
  quantity: number
  productKey: string
  options: Record<string, string>
  trackingKey: string
  tenant: string

  shouldCheckProductStatus = false

  constructor (project: any, productKey: string, isProductAvailable: boolean) {
    super(project, isProductAvailable)
    this.id = project.documentId
    this.name = project.name
    this.created = new Date(project.createdAt * 1000)
    this.modified = new Date(project.lastUpdatedAt * 1000)
    this.editUrl = project.vpEditUrl
    this.hasCopy = false
    this.canRename = false

    this.addToCartOperation = AddToCartOperation.OpenStudioModal

    this.createdInOfflineEditor =
      project?.attributes?.Editor === 'WindowsEditor' || project?.attributes?.Editor === 'MacEditor'
    this.productKey = productKey
    this.options = this.parseOptions(project?.selections) ?? {}
    this.quantity = 1
    this.trackingKey = 'albelli'
    this.tenant = ''
  }

  parseOptions (selections: string | undefined) {
    if (!selections) {
      return {}
    }

    try {
      return JSON.parse(selections)
    } catch (ex) {
      return {}
    }
  }
}

import { PreviewModes, PurcsPreview } from '@vp/react-product-preview'
import '@vp/react-product-preview/dist/cjs/index.css'
import { LazyLoadingImage } from '../../LazyLoadingImage'
import { Project } from '../../../domain/model/project'
import { useIdentity, useUserContext } from '@vp/ubik-context'
import { REQUESTOR } from '../../../constants'
import { useLocalization } from '../../../hooks/useLocalizations'

const PURCS_VISTAPRINT_TENANT = 'vistaprint-prod'
const PURCS_PROMOTIQUE_TENANT = 'promotique-prod'

const getPurcsTenant = (tenant: string) => purcsTenants[tenant] || purcsTenants.default

const purcsTenants: Record<string, string> = {
  vistaprint: PURCS_VISTAPRINT_TENANT,
  promotique: PURCS_PROMOTIQUE_TENANT,
  brandstore: PURCS_PROMOTIQUE_TENANT,
  default: PURCS_VISTAPRINT_TENANT,
}

type CarouselProductImageProps = {
  project: Project;
}

export const CarouselProductImage = ({ project }: CarouselProductImageProps) => {
  if (project.imageUrl) {
    return <LazyLoadingImage src={project.imageUrl} alt={project.name} hasBadge={false} />
  }

  return <Carousel project={project} />
}

const Carousel = ({ project }: CarouselProductImageProps) => {
  const { identity } = useIdentity()
  const { locale, tenant } = useUserContext()

  const { t } = useLocalization()

  return (
    <PurcsPreview
      requestor={REQUESTOR}
      locale={locale}
      tenantId={getPurcsTenant(tenant as string)}
      width={500}
      previewMode={PreviewModes.Carousel}
      optionSelections={project.options}
      workRevisionId={project.workRevisionId}
      authorization={identity?.authorizationHeader}
      coreProductKey={project.productKey}
      productVersion={project.productVersion}
      accessibleTextForPrevious={t('projectInfo.textForPrev')}
      accessibleTextForNext={t('projectInfo.textForNext')}
      previewInstructionsUri={undefined}
    />
  )
}

import { Project } from '../../..//domain/model/project'
import { useLocalization } from '../../../hooks/useLocalizations'
import NameInputModal from '../../NameInputModal'

type RenameProjectModalProps = {
  project: Project;
  isOpen: boolean;
  onClose: () => void;
  onRename: (newName: string) => Promise<void>;
}
export const RenameProjectModal = ({ project, isOpen, onClose, onRename }: RenameProjectModalProps) => {
  const { t } = useLocalization()

  return (
    <NameInputModal
      title={t('nameInputModal.rename.title', { projectName: project.name })}
      submitButtonLabel={t('nameInputModal.rename.submit')}
      isOpen={isOpen}
      errorMessage={t('nameInputModal.rename.error')}
      onSubmit={onRename}
      onClose={onClose}
      data-testid='rename-modal'
    />
  )
}

import { useEffect, useState } from 'react'
import { initialize, activate, whenAvailable } from '@vp/ab-reader'
import { MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT_VARIANTS, MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT } from '../constants'

export const useProjectsButtonExperienceVariation = (): string => {
  const [buttonsExperienceVariation, setButtonsExperienceVariation] = useState(
    MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT_VARIANTS.CONTROL
  )

  useEffect(() => {
    initialize()
    whenAvailable((available) => {
      if (!available) {
        return
      }
      const myProjectsButtonsExperienceVariation = activate(MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT) as string
      setButtonsExperienceVariation(myProjectsButtonsExperienceVariation)
    })
  }, [])

  return buttonsExperienceVariation
}

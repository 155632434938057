import { fetchLegacyDocumentsPage, importLegacyDocument } from '../../clients/legacy.proxy'
import { createNewWorkEntity, deleteWorkEntity } from '../../clients/wesProjects.proxy'
import { LegacyProject, LegacyProjectStatus } from '../model/legacyProject'
import { PaginatedProjectsList } from '../model/paginatedProjectsList'
import { Logger } from '@vp/ubik-logging'

const PAGE_SIZE = 50

const getAllLegacyProjects = async (locale: string, owner: string, authorizationHeader : string, logger : Logger) => {
  try {
    const legacyProjects: any[] = []
    let offset = 0
    let hasMorePages = true

    while (hasMorePages) {
      const currentPage = await fetchLegacyDocumentsPage(locale, offset, PAGE_SIZE, owner, authorizationHeader)
      offset += PAGE_SIZE
      hasMorePages = currentPage.length === 50

      if (currentPage.length) legacyProjects.push(...currentPage)
    }

    const projectIdsWithDesignRequests : string[] = []

    return new PaginatedProjectsList<LegacyProject>().add(
      legacyProjects.map((p) => mapToLegacyProject(p, projectIdsWithDesignRequests))
    )
  } catch (error: any) {
    logger.error('Failed to fetch legacy projects', error)

    return new PaginatedProjectsList<LegacyProject>()
  }
}

function mapToLegacyProject (serviceProject: any, projectIdsWithDesignRequests: string[]): LegacyProject {
  return {
    id: serviceProject.altDocId,
    name: serviceProject.documentName || serviceProject.productName,
    previewUrl: `https://rendering.documents.cimpress.io/v1/dcl/preview?format=jpg&&width=200&instructions_uri=${encodeURIComponent(
      serviceProject.previewUrl
    )}`,
    status: mapStatus(serviceProject, projectIdsWithDesignRequests),
    modified: new Date(),
    productName: '',
  }
}

function mapStatus (serviceProject: any, projectIdsWithDesignRequests: string[]) {
  const updateRequested = projectIdsWithDesignRequests.includes(serviceProject.altDocId)

  if (serviceProject.importable) {
    return LegacyProjectStatus.AutomaticallyImportable
  }

  if (updateRequested) {
    return LegacyProjectStatus.ManualUpdateInProgress
  }

  return LegacyProjectStatus.ManualUpdateRequired
}

const deleteLegacyProject = async (project: LegacyProject, owner: string, authorizationHeader : string) => {
  const work = {
    workName: `DELETED - ${project.name}`,
    design: {
      metadata: {
        importData_altDocId: project.id,
      },
    },
    resources: {
      hideFromMyProjects: true,
    },
  }

  const createdWork = await createNewWorkEntity(work, '', owner, authorizationHeader) as any
  await deleteWorkEntity(createdWork.workId, authorizationHeader)
}

const importLegacyProject = async (locale: string, project: LegacyProject, authorizationHeader: string) => {
  await importLegacyDocument(locale, project.id, authorizationHeader)
}

export { getAllLegacyProjects, deleteLegacyProject, importLegacyProject }

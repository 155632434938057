export const helpCenterLink: Record<string, string> = {
  'en-gb': 'https://www.vistaprint.co.uk/customer-care/help-centre/',
  'en-ie': 'https://www.vistaprint.ie/customer-care/help-centre/',
  'es-es': 'https://www.vistaprint.es/atencion-al-cliente/centro-de-ayuda/',
  'pt-pt': 'https://www.vistaprint.pt/servico-apoio-cliente/centro-ajuda/',
  'it-it': 'https://www.vistaprint.it/assistenza-clienti/centro-assistenza/',
  'nl-nl': 'https://www.vistaprint.nl/klantenservice/help/',
  'nl-be': 'https://www.vistaprint.be/klantenservice/help/',
  'fr-be': 'https://www.vistaprint.be/fr/service-client/centre-assistance/',
  'nb-no': 'https://www.vistaprint.no/kundeservice/help/',
  'da-dk': 'https://www.vistaprint.dk/kundeservice/hjaelp/',
  'sv-se': 'https://www.vistaprint.se/kundservice/hjalpcenter/',
  'fi-fi': 'https://www.vistaprint.fi/asiakaspalvelu/ohjekeskus/',
  'de-de': 'https://www.vistaprint.de/kundenservice/hilfe-seite/',
  'de-at': 'https://www.vistaprint.at/kundenservice/hilfe-seite/',
  'de-ch': 'https://www.vistaprint.ch/kundenservice/hilfe-seite/',
  'it-ch': 'https://www.vistaprint.ch/it/assistenza-clienti/centro-assistenza/',
  'fr-ch': 'https://www.vistaprint.ch/fr/service-client/centre-assistance/',
  'fr-fr': 'https://www.vistaprint.fr/service-client/centre-assistance/',
  'en-au': 'https://www.vistaprint.com.au/customer-care/help-centre/',
  'en-nz': 'https://www.vistaprint.co.nz/customer-care/help-centre/',
  'en-sg': 'https://www.vistaprint.sg/customer-care/help-centre/',
  'en-in': 'https://www.vistaprint.in/customer-care/help-centre/',
  'en-ca': 'https://www.vistaprint.ca/customer-care/help-center/',
  'fr-ca': 'https://www.vistaprint.ca/fr/service-clientele/centre-assistance/',
  'en-us': 'https://www.vistaprint.com/customer-care/help-center/',
  'es-us': 'https://www.vistaprint.com/es/atencion-al-cliente/centro-de-ayuda/',
}

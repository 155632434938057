import { Logo, OldLogo } from '../domain/model/logoMaker'

const urls = {
  staging: 'https://stage-logos-service.algo.design.vpsvc.com/',
  production: 'https://logos-service.algo.design.vpsvc.com/',
}

export async function deleteLogo (logoId: string, tenant: string, authorizationHeader: string) {
  const url = getUrl(tenant)
  const response = await fetch(`${url}v2/logos/${logoId}`, {
    method: 'DELETE',
    headers: { authorization: authorizationHeader },
    body: JSON.stringify({ deleteFromService: ['WES'] }),
  })

  if (!response.ok) {
    throw new Error('Failed to delete logo')
  }

  return response.json()
  // return await client.delete(`${url}v2/logos/${logoId}`, { deleteFromService: ['WES'] })
}

function isNewLogo (logo: unknown): logo is Logo {
  return (logo as Logo).masterVariationData !== undefined
}

function isOldLogo (logo: unknown): logo is OldLogo {
  return (logo as OldLogo).variation !== undefined
}

export async function renameLogo (logoId: string, newName: string, logoTenant: string, authorizationHeader: string) {
  const url = getUrl(logoTenant)

  const response = await fetch(`${url}v2/logos/${logoId}`, { headers: { authorization: authorizationHeader } })
  const logo = await response.json()
  // const logo = await client.get(`${url}v2/logos/${logoId}`)

  let composerSignature
  if (isNewLogo(logo)) {
    composerSignature = logo.masterVariationData.composerSignature
  } else if (isOldLogo(logo)) {
    composerSignature = logo.variation.primary.composerSignature
  } else {
    throw new Error('Invalid logo type')
  }
  const { source, tenant, version } = logo
  const updateLogo = {
    name: newName,
    source,
    tenant,
    version,
    logoData: {
      logoVariationType: 'PRIMARY',
      logoComposerSignature: composerSignature,
    },
    clientOptions: {
      clientId: 'VISTAPRINT',
      data: {
        wes: {
          sendToWes: true,
          editUrl: `/logomaker/edit-logo/${logoId}`,
        },
      },
    },
    action: 'SAVE',
  }

  const putResponse = await fetch(`${url}v2/logos/${logoId}`, { method: 'PUT', headers: { authorization: authorizationHeader }, body: JSON.stringify(updateLogo) })

  if (!putResponse.ok) {
    throw new Error('Failed to rename logo')
  }

  return putResponse.json()
}

function getUrl (tenant: string) {
  return tenant === 'LOGO-UNPURCHASED-PROD' ? urls.production : urls.staging
}

import { FlexBox, Typography, Icon } from '@vp/swan'
import { useContext, useState } from 'react'
import { renameProject } from '../../domain/commands/renameCommand'
import NameInputModal from '../NameInputModal'
import { useLocalization } from '../../hooks/useLocalizations'
import { useOwner } from '../../hooks/useOwner'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { trackProjectEvent } from '../Analytics/trackingUtils'
import { TrackingEventLabels } from '../../constants'
import { Project } from '../../domain/model/project'
import { ProjectListContext } from '../../context/ProjectListContext'

const ProjectName = (props: { project: Project }) => {
  const { tenant } = useUserContext()
  const { refresh } = useContext(ProjectListContext)
  const logger = useLogger()
  const { identity } = useIdentity()
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)
  const { project } = props
  const { t } = useLocalization()

  const owner = useOwner()

  const renameProjectAndRefresh = async (newName: string) => {
    trackProjectEvent(TrackingEventLabels.RENAME, project)
    await renameProject(project, newName, tenant as string, owner, logger, identity?.authorizationHeader as string)
    refresh()
  }

  return project.canRename
    ? (
      <>
        <NameInputModal
          title={t('nameInputModal.rename.title', { projectName: project.name })}
          submitButtonLabel={t('nameInputModal.rename.submit')}
          isOpen={isRenameModalOpen}
          errorMessage={t('nameInputModal.rename.error')}
          onSubmit={renameProjectAndRefresh}
          onClose={() => setIsRenameModalOpen(false)}
          data-testid='rename-modal'
        />
        <FlexBox alignItems='center' style={{ cursor: 'pointer' }} onClick={() => setIsRenameModalOpen(true)}>
          <Typography fontSkin='title-subsection' mr='3'>
            {project.name}
          </Typography>
          <Icon iconType='edit' size='20p' />
        </FlexBox>
      </>
      )
    : (
      <Typography fontSkin='title-subsection' mr='3'>
        {project.name}
      </Typography>
      )
}
export default ProjectName

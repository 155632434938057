import { Button, responsive, Spinner, useScreenClass, Icon } from '@vp/swan'
import { useState } from 'react'
import { AddToCartOperation, Project } from '../../domain/model/project'
import { getNextStep } from '../../clients/siteFlow.proxy'
import { trackProjectEvent } from '../../components/Analytics/trackingUtils'
import { useProjectHasAddToCart } from '../../hooks/useProjectHasAddToCart'
import AddToCartConfirmationModal from './AddToCartConfirmationModal'
import { SpinnerButtonProps } from '../SpinnerButton'
import { useTracking } from '../../context/TrackingContext'
import { useLogger, useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../hooks/useLocalizations'

type AddToCartButtonProps = {
  project: Project;
  onError: (error: any) => void;
  children: React.ReactNode;
  buttonProps?: Partial<SpinnerButtonProps>;
}

export const AddToCartButton = ({ project, onError, children, buttonProps }: AddToCartButtonProps) => {
  const [isAddToCartConfirmationModalOpen, setIsAddToCartConfirmationModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { locale, tenant } = useUserContext()
  const logger = useLogger()
  const { labels } = useTracking()
  const { t } = useLocalization()

  const isAddToCartEnabled = useProjectHasAddToCart(project)

  const screenSize = useScreenClass()
  const isMobileOrTablet = screenSize === 'xs' || screenSize === 'sm'

  const ResponsiveButton = responsive(Button)

  const handleOnClick = async () => {
    setIsLoading(true)

    try {
      await addToCartClicked()
    } catch (e) {
      setIsLoading(false)
      throw e
    }

    setIsLoading(false)
  }

  const addToCartClicked = async () => {
    try {
      trackProjectEvent(labels.ADD_TO_CART, project)
      if (project.addToCartOperation === AddToCartOperation.OpenStudioModal) {
        setIsAddToCartConfirmationModalOpen(true)
        return
      }

      await addProjectToCart()
    } catch (error: any) {
      logger.error('Add to cart failed', error as Error, { project })
      onError(error)
    }
  }

  const addProjectToCart = async () => {
    let nextStepUrl = ''
    let nextStep

    if (project.productKey && project.productVersion !== undefined) {
      nextStep = await getNextStep(tenant as string, locale, project.productKey, project.productVersion?.toString())
    }

    nextStepUrl = nextStep || '/c/'
    if (nextStepUrl === '/c/') {
      nextStepUrl = project.editUrl
    }

    window.location.assign(nextStepUrl.replace('{workId}', project.id))
  }

  return (
    <>
      <AddToCartConfirmationModal
        project={project}
        isOpen={isAddToCartConfirmationModalOpen}
        onClose={() => setIsAddToCartConfirmationModalOpen(false)}
      />
      <ResponsiveButton
        skin={buttonProps?.skin ?? 'primary'}
        disabled={isLoading || !isAddToCartEnabled}
        onClick={handleOnClick}
        width={buttonProps?.width ?? (isMobileOrTablet ? 'full-width' : 'standard')}
        iconPosition='left'
      >
        {isLoading && <Spinner accessibleText={t('loading.preloaderLabel')} size='tiny' />}
        {!isLoading && <Icon iconType='shoppingBag_0' />}
        {children}
      </ResponsiveButton>
    </>
  )
}

export default AddToCartButton

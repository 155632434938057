export enum TrackingEventLabels {
  ADD_TO_CART = 'Add to cart',
  DELETE = 'Delete project',
  EDIT = 'Edit project',
  RENAME = 'Rename project',
  COPY = 'Copy project',
  DOWNLOAD_LOGO = 'Download Logo',
  DESIGN_HELP = 'Design help',
  REQUEST_A_REVISION = 'Request a revision',
  VIEW_DESIGN = 'View design',
  SEARCH = 'Search',
  LEGACY_IMPORT = 'Legacy Import Clicked',
  LEGACY_DELETE = 'Legacy delete',
  OPEN_PROJECT_DETAILS_MODAL = 'Open project details modal',
  WORK_WITH_A_DESIGNER = 'Work with a designer',

  PROJECT_DETAILS_MODAL_ADD_TO_CART = 'Add to cart: Project details modal',
  PROJECT_DETAILS_MODAL_DELETE = 'Delete project: Project details modal',
  PROJECT_DETAILS_MODAL_EDIT = 'Edit project: Project details modal',
  PROJECT_DETAILS_MODAL_RENAME = 'Rename project: Project details modal',
  PROJECT_DETAILS_MODAL_COPY = 'Copy project: Project details modal',
  PROJECT_DETAILS_MODAL_DESIGN_HELP = 'Design help: Project details modal',
  PROJECT_DETAILS_MODAL_REQUEST_A_REVISION = 'Request a revision: Project details modal',
  PROJECT_DETAILS_MODAL_VIEW_DESIGN = 'View design: Project details modal',
}

export const REQUESTOR = 'my-projects'
export const DEFAULT_TENANT = 'vistaprint'
export enum LOGO_STATUS {
  LOGO_ORDER_PROCESSING = 'LOGO_ORDER_PROCESSING',
  LOGO_COMPLETED = 'LOGO_COMPLETED',
  LOGO_ADDED_TO_CART = 'LOGO_ADDED_TO_CART',
  LOGO_SAVED = 'LOGO_SAVED',
}
export const ON_DEMAND_GEN_AI_LOGO = 'On demand Gen AI Logo'

export const ITEMS_PER_PAGE_OPTIONS = [5, 10, 25]

export const PROJECTS_CONTAINED_ELEMENT_ID = 'projects-contained-element'

export const MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT = 'my_projects_buttons_experience'
export const MY_PROJECTS_BUTTONS_EXPERIENCE_EXPERIMENT_VARIANTS = {
  CONTROL: 'control',
  V1: 'v1',
  V2: 'v2',
}

export const LOGOS_BASE_URL = 'https://logos-service.algo.design.vpsvc.com'

export const DEFAULT_LOCALE = 'en-IE'

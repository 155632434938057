import { LocalStorageCacheService } from '@vp/cache-agent'
import {
  fetchAllAvailableProducts,
  fetchPRDFromMPV,
  fetchMpvIdFromProductKey,
} from '../../clients/msx.proxy'
import { ProductOptionsTranslations } from '../../domain/model/product'
import { Logger } from '@vp/ubik-logging'

export const mapTranslatedOptions = (
  sourceOptions: Record<string, string>,
  translatedOptionsNames: ProductOptionsTranslations[]
) => {
  const result: Record<string, string> = {}

  if (!translatedOptionsNames?.length) return sourceOptions

  Object.keys(sourceOptions).forEach((optionKey) => {
    const translationObject = translatedOptionsNames?.find((x) => x.key.key === optionKey)
    const keyName = translationObject?.key.merchandisingName
    const valueObject = translationObject?.value.find((x:any) => x.key === sourceOptions[optionKey])
    const valueName = valueObject?.merchandisingName

    if (keyName && valueName) {
      result[keyName] = valueName
    }
  })

  return result
}

export const getAllAvailableProducts = async (tenant: string, locale: string, logger : Logger) => {
  const localStorageCache = new LocalStorageCacheService<string[]>(60)
  const localStorageKey = `vp-availableProducts-${tenant}-${locale}`

  return await localStorageCache.getData(localStorageKey, () => fetchAllAvailableProducts(tenant, locale, logger))
}

export const getPRDFromMPV = async (tenant: string, locale: string, mpv: string, logger : Logger) => {
  const localStorageCache = new LocalStorageCacheService<string>(60)
  const localStorageKey = `vp-mpvprdmap-${tenant}-${locale}-${mpv}`

  return await localStorageCache.getData(localStorageKey, async () => {
    const result = await fetchPRDFromMPV(tenant, locale, mpv, logger)
    if (!result) throw new Error('Failed to fetch PRD from MPV')
    return result
  })
}

export const getMpvIdFromProductKey = async (tenant: string, locale: string, productKey: string, logger : Logger) => {
  const localStorageCache = new LocalStorageCacheService<string>(60)
  const localStorageKey = `vp-productkeympv-${tenant}-${locale}-${productKey}`

  return await localStorageCache.getData(localStorageKey, async () => {
    const result = await fetchMpvIdFromProductKey(tenant, locale, productKey, logger)

    if (result === undefined) throw new Error('Failed to fetch MpvId from product key')

    return result || ''
  })
}

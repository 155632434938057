import pLimit from 'p-limit'
import { REQUESTOR } from '../constants'
import { Logger } from '@vp/ubik-logging'
const MAX_PAGE_SIZE = 50
const WES_DOMAIN = 'https://workentityservice.docext.cimpress.io'
const WES_VISTAPRINT_TENANT = 'VISTAPRINT-PROD,LOGO-UNPURCHASED-PROD,VISTACREATE-UNP-PROD'
const WES_PROMOTIQUE_TENANT = 'PROMOTIQUE-PROD'
const WES_DEFAULT_TENANT = 'VISTAPRINT-PROD'

const getWesTenant = (tenant: string) => wesTenants[tenant] || wesTenants.default

type WorkEntity = any

const wesTenants: Record<string, string> = {
  vistaprint: WES_VISTAPRINT_TENANT,
  promotique: WES_PROMOTIQUE_TENANT,
  brandstore: WES_PROMOTIQUE_TENANT,
  default: WES_DEFAULT_TENANT,
}

export const fetchFirstPageOfWesProjects = async (tenant: string, owner: string, logger: Logger, authorizationHeader: string): Promise<any[]> =>
  await fetchWesPageOfWorks(tenant, MAX_PAGE_SIZE, 0, owner, logger, authorizationHeader) as any[]

export const fetchRestOfWesProjects = async (
  wesProjectsCount: number,
  tenant: string,
  owner: string,
  logger: Logger,
  authorizationHeader: string
) => {
  const offsets = []
  for (let i = MAX_PAGE_SIZE; i < wesProjectsCount; i += MAX_PAGE_SIZE) {
    offsets.push(i)
  }

  if (offsets.length === 0) return []

  const limit = pLimit(10)

  const limitedPromise = offsets.map((offset) =>
    limit(() => fetchWesPageOfWorks(tenant, MAX_PAGE_SIZE, offset, owner, logger, authorizationHeader))
  )

  const allWorks = await Promise.all(limitedPromise)

  return allWorks.flat()
}

async function fetchWesPageOfWorks (
  tenant: string,
  pageSize: number,
  offset: number,
  owner: string,
  logger: Logger,
  authorizationHeader: string
) {
  let url

  try {
    const ownerQp = owner ? `&ownerId=${owner}` : ''
    const offsetQp = offset ? `&offset=${offset}` : ''
    url =
      WES_DOMAIN +
      `/api/v2/works?from=${REQUESTOR}&tenants=${getWesTenant(
        tenant
      )}&pageSize=${pageSize}${ownerQp}${offsetQp}&sortBy=LastModified`
    const result = await fetch(url, {
      headers: {
        Authorization: authorizationHeader
      }
    })

    if (!result.ok) {
      throw new Error(`Failed to fetch page of works. Status: ${result.status}`)
    }

    return await result.json()
  } catch (error: any) {
    logger.error('Failed to fetching page of works', error as Error, {
      url,
      message: error.message,
      status: error.status,
    })
    return []
  }
}

export async function fetchWesProjectCount (tenant: string, owner: string, logger: Logger, authorizationHeader: string) {
  let url
  try {
    const ownerQp = owner ? `&ownerId=${owner}` : ''
    url = WES_DOMAIN + `/api/v2/works/count?from=${REQUESTOR}&tenants=${getWesTenant(tenant)}${ownerQp}`
    const result = await fetch(url, {
      headers: {
        Authorization: authorizationHeader
      }
    })

    if (!result.ok) {
      throw new Error(`Failed to fetch WES projects count. Status: ${result.status}`)
    }

    const fetchedProjects = await result.json() as any
    return fetchedProjects.ownedWorks
  } catch (error) {
    logger.error('Failed to fetch WES projects count', error as Error, { url })
    return 0
  }
}

export async function renameWorkEntity (
  workId: string,
  newName: string,
  tenant: string,
  owner: string,
  authorizationHeader: string
) {
  const workEntityToRename: any = await fetchWorkEntity(workId, tenant, authorizationHeader)

  workEntityToRename.workName = newName

  return updateWorkEntity(workEntityToRename, owner, authorizationHeader)
}

export async function fetchWorkEntity (workId: string, tenant: string, authorizationHeader: string) {
  const result = await fetch(
    WES_DOMAIN + `/api/v1/works/${workId}?from=${REQUESTOR}&tenant=${getWesTenant(tenant)}`,
    {
      headers: {
        Authorization: authorizationHeader
      }
    }
  )

  if (!result.ok) {
    throw new Error(`Failed to fetch work entity. Status: ${result.status}`)
  }

  return await result.json() as WorkEntity
}

export async function createNewWorkEntity (data: any, tenant: string, owner: string, authorizationHeader: string) {
  const result = await fetch(
    WES_DOMAIN +
      `/api/v1/works?from=${REQUESTOR}&tenant=${tenant || getWesTenant('default')}${owner ? `&ownerId=${owner}` : ''}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationHeader
      },
      body: JSON.stringify(data)
    }
  )

  if (!result.ok) {
    throw new Error(`Failed to create new work entity. Status: ${result.status}`)
  }

  return await result.json()
}

export async function updateWorkEntity (data: any, owner: string, authorizationHeader: string) {
  const result = await fetch(
    WES_DOMAIN + `/api/v1/works/${data.workId}/update?from=${REQUESTOR}${owner ? `&ownerId=${owner}` : ''}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationHeader
      },
      body: JSON.stringify(data)
    }
  )

  if (!result.ok) {
    throw new Error(`Failed to update work entity. Status: ${result.status}`)
  }

  return await result.json()
}

export async function deleteWorkEntity (workId: string, authorizationHeader: string) {
  const result = await fetch(WES_DOMAIN + `/api/v1/works/${workId}/setVisible?from=${REQUESTOR}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationHeader
    },
    body: JSON.stringify({ hidden: true })
  })

  if (!result.ok) {
    throw new Error(`Failed to delete work entity. Status: ${result.status}`)
  }
}

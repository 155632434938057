/// <reference lib="dom" />
import { LOGOS_BASE_URL } from '../constants'

export const downloadLogoZipFromAPI = async (logoId: string, authorizationHeader: string): Promise<void> => {
  try {
    const response = await fetch(`${LOGOS_BASE_URL}/v1/logos/${logoId}/download?downloadFormat=ZIP`, {
      headers: {
        Authorization: authorizationHeader,
      },
    })

    if (!response.ok) {
      throw new Error('Failed to download the logo.')
    }

    // Convert the response to a Blob
    const blob = await response.blob()

    if (!blob) {
      throw new Error('Failed to retrieve the Blob')
    }

    // Create an anchor element to trigger the download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `Vista Logos ${logoId}.zip` // Set the filename

    // Trigger the download
    document.body.appendChild(link)
    link.click()

    // Clean up and remove the link
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href)
  } catch (error) {
    throw new Error('Error while downloading logo')
  }
}

import { Button, Card, FlexBox, Icon, Typography } from '@vp/swan'
import { useState } from 'react'
import { LegacyProject, LegacyProjectStatus } from '../../domain/model/legacyProject'
import { deleteLegacyProject, importLegacyProject } from '../../domain/services/legacyProjectService'
import { REQUESTOR, TrackingEventLabels } from '../../constants'
import { trackLegacyProjectEvent } from '../Analytics/trackingUtils'
import DeleteConfirmationModal from '../actionButtons/DeleteConfirmationModal'
import { LazyLoadingImage } from '../LazyLoadingImage'
import SpinnerButton from '../SpinnerButton'
import ImportFailedModal from './ImportFailedModal'
import { LegacyRecreationModalContextProvider } from '../../context/LegacyRecreationModalProvider'
import { useOwner } from '../../hooks/useOwner'
import { useLocalization } from '../../hooks/useLocalizations'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'

const LegacyProjectCard = (props: { project: LegacyProject; onRefreshRequested: () => void }) => {
  const { project, onRefreshRequested } = props
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [legacyRecreationModalIsOpen, setLegacyRecreationModalIsOpen] = useState(false)
  const [importFailedModalOpen, setImportFailedModalOpen] = useState(false)
  const { locale } = useUserContext()
  const owner = useOwner()
  const { t } = useLocalization()
  const logger = useLogger()
  const { identity } = useIdentity()

  const handleDeleteLegacyProject = async () => {
    try {
      trackLegacyProjectEvent(TrackingEventLabels.LEGACY_DELETE, project)
      await deleteLegacyProject(project, owner, identity?.authorizationHeader as string)
      setDeleteConfirmationModalOpen(false)
      onRefreshRequested()
    } catch (error) {
      logger.error('Error deleting legacy project', error as Error, { project })
    }
  }

  const handleImportLegacyProject = async () => {
    try {
      trackLegacyProjectEvent(TrackingEventLabels.LEGACY_IMPORT, project)
      await importLegacyProject(locale, project, identity?.authorizationHeader as string)
      onRefreshRequested()
    } catch (error) {
      logger.error('Error importing legacy project', error as Error, { project })

      setImportFailedModalOpen(true)
    }
  }

  return (
    <LegacyRecreationModalContextProvider
      culture={locale}
      onCompletion={() => {}}
      documentName={project.name}
      legacyDocumentId={project.id}
      legacyProductName={project.name}
      requestor={`${REQUESTOR}-legacydocumentcard`}
      isOpen={legacyRecreationModalIsOpen}
      onRequestDismiss={() => setLegacyRecreationModalIsOpen(false)}
    >
      <Card bordered>
        <ImportFailedModal
          isOpen={importFailedModalOpen}
          onSubmit={() => {
            setLegacyRecreationModalIsOpen(true)
            setImportFailedModalOpen(false)
          }}
          onClose={() => setImportFailedModalOpen(false)}
        />
        <DeleteConfirmationModal
          isOpen={deleteConfirmationModalOpen}
          onDelete={handleDeleteLegacyProject}
          onClose={() => setDeleteConfirmationModalOpen(false)}
        />
        <FlexBox flexDirection='column' alignItems='center'>
          <LazyLoadingImage src={project.previewUrl} alt='' hasBadge={false} />
          <Typography mt='6' fontSkin='body-standard-bold'>
            {project.name || t('legacyProjects.defaultProjectName')}
          </Typography>
          <Typography mb='4'>ID#: {project.id}</Typography>
          <FlexBox>
            {project.status === LegacyProjectStatus.AutomaticallyImportable && (
              <SpinnerButton onClick={handleImportLegacyProject} skin='primary'>
                {t('legacyProjects.importButton')}
              </SpinnerButton>
            )}
            {project.status === LegacyProjectStatus.ManualUpdateInProgress && (
              <Button disabled>{t('legacyProjects.updateInProgressButton')}</Button>
            )}
            {project.status === LegacyProjectStatus.ManualUpdateRequired && (
              <Button skin='primary' onClick={() => setLegacyRecreationModalIsOpen(true)}>
                {t('legacyProjects.requestUpdateButton')}
              </Button>
            )}
            {project.status !== LegacyProjectStatus.ManualUpdateInProgress && (
              <Button ml='4' iconPosition='left' onClick={() => setDeleteConfirmationModalOpen(true)}>
                <Icon iconType='delete' />
                {t('legacyProjects.deleteButton')}
              </Button>
            )}
          </FlexBox>
        </FlexBox>
      </Card>
    </LegacyRecreationModalContextProvider>
  )
}
export default LegacyProjectCard

import React from 'react'
import { useLocalization } from './useLocalizations'

enum TimeLapse {
  FewSecondsAgo = 30,
  Minute = 60,
  Hour = Minute * 60,
  Day = Hour * 24,
  Month = Day * 28,
  Year = Day * 365,
}

export const useRelativeTime = (referenceDate: Date | undefined): string | null => {
  const { t } = useLocalization()
  return React.useMemo(() => {
    if (referenceDate === undefined) {
      return null
    }

    const now = Date.now()
    const referenceDateTime = referenceDate.getTime()

    const formatPlural = (relativeTime: number, subject: string) => {
      return relativeTime === 1
        ? t(`projectInfo.editedDate.edited${subject}`, { relativeTime: relativeTime.toString() })
        : t(`projectInfo.editedDate.edited${subject}s`, { relativeTime: relativeTime.toString() })
    }

    if (!referenceDateTime || isNaN(referenceDateTime)) {
      return null
    }

    // The below code gets the milliseconds elapsed since last edit
    // As per milliseconds elapsed, appropriate message is displayed
    const timeDiff = Math.floor((now - referenceDateTime) / 1000)
    if (timeDiff >= TimeLapse.Year) {
      return formatPlural(Math.floor(timeDiff / TimeLapse.Year), 'Year')
    }
    if (timeDiff >= TimeLapse.Month) {
      return formatPlural(Math.floor(timeDiff / TimeLapse.Month), 'Month')
    }
    if (timeDiff >= TimeLapse.Day) {
      return formatPlural(Math.floor(timeDiff / TimeLapse.Day), 'Day')
    }
    if (timeDiff >= TimeLapse.Hour) {
      return formatPlural(Math.floor(timeDiff / TimeLapse.Hour), 'Hour')
    }
    if (timeDiff >= TimeLapse.Minute) {
      return formatPlural(Math.floor(timeDiff / TimeLapse.Minute), 'Minute')
    }
    if (timeDiff >= TimeLapse.FewSecondsAgo) {
      return formatPlural(timeDiff, 'Second')
    }
    return t('projectInfo.editedDate.editedFewSeconds')
  }, [t, referenceDate])
}

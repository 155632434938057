import { Logger } from '@vp/ubik-logging'
import { Project } from '../model/project'
import { WesProject } from '../model/wesProject'
import { clone as cloneWes } from '../services/wesProjectsService'

export const copyProject = async (
  project: Project,
  newName: string,
  tenant: string,
  owner: string,
  logger: Logger,
  authorizationHeader: string
) => {
  if (project instanceof WesProject) {
    await cloneWes(project.id, tenant, owner, logger, authorizationHeader, newName)
  } else {
    logger.error(
      'Unknown project type for commands. You need to add all the project types to all the commands (copying projects)',
      new Error('Unknown project type for commands (copying projects).'),
      { project }
    )

    throw new Error('Unknown project type for commands.')
  }
}

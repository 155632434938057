import {
  FlexBox,
  Dropdown,
  DropdownOption,
  Pagination,
  PaginationButton,
  PaginationStep,
  PaginationEllipses,
  useScreenClass,
  useIsScreenClassInitialized,
  Box,
} from '@vp/swan'
import React, { FC, useEffect } from 'react'
import { navigateToInternalLink } from '../utils/browserUtils'
import { useLocalization } from '../hooks/useLocalizations'

const INITIAL_PAGE = 1
const MAXIMUM_VISIBLE_PAGINATION_STEPS = 5
const NUMBER_OF_ADJACENT_PAGINATION_STEPS_VISIBLE = 1

export interface ItemsPaginationProps {
  items: any[];
  itemsPerPageOptions: number[];
  onVisibleItemsChanged: (items: any[]) => void;
  selectedItemsPerPage?: number;
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  selectedPage?: number;
  onPageChange?: (page: number) => void;
  parentId: string;
}

const ItemsPagination: FC<ItemsPaginationProps> = (props: ItemsPaginationProps) => {
  const { items, itemsPerPageOptions, onVisibleItemsChanged, parentId, selectedItemsPerPage, selectedPage } = props
  const initialItemsPerPageOption = itemsPerPageOptions[0]

  const [page, setPage] = React.useState(INITIAL_PAGE)
  const [numberOfPages, setNumberOfPages] = React.useState(1)
  const [itemsPerPage, setItemsPerPage] = React.useState(initialItemsPerPageOption)

  const currentViewport = useScreenClass()
  const isScreenClassInitialized = useIsScreenClassInitialized()
  const isMobile = currentViewport === 'xs' || currentViewport === 'sm'
  const { t } = useLocalization()

  useEffect(() => {
    if (selectedItemsPerPage) {
      setItemsPerPage(selectedItemsPerPage)
    }
  }, [selectedItemsPerPage])

  useEffect(() => {
    if (selectedPage) {
      setPage(selectedPage)
    }
  }, [selectedPage])

  useEffect(() => {
    const newNumberOfPages = Math.ceil(items.length / itemsPerPage)
    setNumberOfPages(newNumberOfPages)

    const newSelectedPage = calculateNewSelectedPage({ selectedPage: page, numberOfPages: newNumberOfPages })
    setPage(newSelectedPage)

    setVisibleItemsForPage({ selectedPage: newSelectedPage, itemsPerPage })
  }, [items, itemsPerPage])

  useEffect(() => {
    navigateToInternalLink(parentId)
  }, [page, itemsPerPage])

  const isItemsPerPageOptionVisible = items.length > initialItemsPerPageOption
  const isPaginationVisible = numberOfPages > 1

  const onItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const newItemsPerPage = parseInt(event.target.value)
    setItemsPerPage(newItemsPerPage)
    setPage(INITIAL_PAGE)
    setVisibleItemsForPage({ selectedPage: INITIAL_PAGE, itemsPerPage: newItemsPerPage })
    if (props.onItemsPerPageChange) {
      props.onItemsPerPageChange(newItemsPerPage)
    }
  }

  const setVisibleItemsForPage = (params: { selectedPage: number; itemsPerPage: number }) => {
    const { selectedPage, itemsPerPage } = params
    const firstItem = (selectedPage - 1) * itemsPerPage
    const lastItem = firstItem + itemsPerPage
    onVisibleItemsChanged(items.slice(firstItem, lastItem))
  }

  const buildPaginationSteps = () => {
    return Array.from({ length: numberOfPages }, (_, index) => {
      const pageNumber = index + 1
      const isPageStepVisible = isPaginationStepVisible({ pageNumber, numberOfPages, selectedPage: page })
      const shouldHaveEllipsisBefore =
        !isPageStepVisible && pageNumber === INITIAL_PAGE + NUMBER_OF_ADJACENT_PAGINATION_STEPS_VISIBLE
      const shouldHaveEllipsisAfter =
        !isPageStepVisible && pageNumber === numberOfPages - NUMBER_OF_ADJACENT_PAGINATION_STEPS_VISIBLE
      const pageName = t('pagination.paginationStepAccessibleText', { pageNumber: pageNumber.toString() })

      if (!isPageStepVisible && !shouldHaveEllipsisBefore && !shouldHaveEllipsisAfter) return undefined

      return (
        <Box key={`PaginationSteps-${index}`}>
          {shouldHaveEllipsisBefore && <PaginationEllipses key='ellipsesBefore' data-testid='ellipsesBefore' />}
          {isPageStepVisible && (
            <PaginationStep
              href='#'
              key={pageName}
              accessibleText={pageName}
              active={pageNumber === page}
              onClick={(e: any) => {
                setPage(pageNumber)
                props.onPageChange?.(pageNumber)
                setVisibleItemsForPage({ selectedPage: pageNumber, itemsPerPage })
                e.preventDefault()
              }}
            >
              {pageNumber}
            </PaginationStep>
          )}
          {shouldHaveEllipsisAfter && <PaginationEllipses key='ellipsesAfter' data-testid='ellipsesAfter' />}
        </Box>
      )
    })
  }

  if (!isScreenClassInitialized) {
    return <></>
  }

  const flexDirection = isMobile ? 'column-reverse' : 'row'
  const previousPageAccessibleText = t('pagination.previousPageAccessibleText')
  return (
    <>
      {isPaginationVisible && (
        <FlexBox flexDirection={flexDirection}>
          <FlexBox alignItems='center' justifyContent='center' style={{ flex: 1 }}>
            <Pagination mx='3' key='pagination' accessibleText={t('pagination.paginationAccessibleText')}>
              <PaginationButton
                key='previous'
                href='#'
                variant='previous'
                accessibleText={previousPageAccessibleText}
                disabled={page === 1}
                onClick={(e: any) => {
                  if (page === 1) return
                  const newSelectedPage = page - 1
                  setPage(newSelectedPage)
                  props.onPageChange?.(newSelectedPage)
                  setVisibleItemsForPage({ selectedPage: newSelectedPage, itemsPerPage })
                  e.preventDefault()
                }}
              />
              {buildPaginationSteps()}
              <PaginationButton
                key='next'
                variant='next'
                accessibleText={t('pagination.nextPageAccessibleText')}
                disabled={page === numberOfPages}
                onClick={() => {
                  if (page === numberOfPages) return
                  const newSelectedPage = page + 1
                  setPage(newSelectedPage)
                  props.onPageChange?.(newSelectedPage)
                  setVisibleItemsForPage({ selectedPage: newSelectedPage, itemsPerPage })
                }}
              />
            </Pagination>
          </FlexBox>
          {isItemsPerPageOptionVisible && (
            <Dropdown mb={isMobile ? '3' : 0} value={itemsPerPage} onChange={onItemsPerPageChange}>
              {itemsPerPageOptions.map((itemsPerPageOption, index) => (
                <DropdownOption key={index} value={itemsPerPageOption}>
                  {t('pagination.itemsPerPage', {
                    items: itemsPerPageOption.toString(),
                  })}
                </DropdownOption>
              ))}
            </Dropdown>
          )}
        </FlexBox>
      )}
    </>
  )
}

export default ItemsPagination

const calculateNewSelectedPage = (params: { selectedPage: number; numberOfPages: number }): number => {
  const { selectedPage, numberOfPages } = params
  if (selectedPage > numberOfPages) return numberOfPages
  if (selectedPage === 0) return INITIAL_PAGE
  return selectedPage
}

const isPaginationStepVisible = (params: {
  pageNumber: number;
  numberOfPages: number;
  selectedPage: number;
}): boolean => {
  const { pageNumber, numberOfPages, selectedPage } = params
  if (numberOfPages <= MAXIMUM_VISIBLE_PAGINATION_STEPS) {
    return true
  }
  if (pageNumber === 1 || pageNumber === numberOfPages) {
    return true
  }

  return (
    pageNumber >= selectedPage - NUMBER_OF_ADJACENT_PAGINATION_STEPS_VISIBLE &&
    pageNumber <= selectedPage + NUMBER_OF_ADJACENT_PAGINATION_STEPS_VISIBLE
  )
}

import { fetchProductNames } from '../../clients/msx.proxy'
import { fetchStudioEditUrl } from '../../clients/stars.proxy'
import { cloneDocument } from '../../clients/uds.proxy'
import {
  createNewWorkEntity,
  deleteWorkEntity,
  fetchFirstPageOfWesProjects,
  fetchRestOfWesProjects,
  fetchWesProjectCount,
  fetchWorkEntity,
  renameWorkEntity,
} from '../../clients/wesProjects.proxy'
import { LogoMakerProject } from '../model/logoMakerProject'
import { VistaCreateProject } from '../model/vistaCreateProject'
import { WesProject } from '../model/wesProject'
import pLimit from 'p-limit'
import { getAllAvailableProducts } from './msxService'
import { Logger } from '@vp/ubik-logging'

export const getFirstPageOfWesProjects = async (
  tenant: string,
  locale: string,
  owner: string,
  logger: Logger,
  authorizationHeader: string
) => {
  try {
    let wesProjectsFromService = await fetchFirstPageOfWesProjects(tenant, owner, logger, authorizationHeader)
    wesProjectsFromService = patchProductKeys(wesProjectsFromService)
    wesProjectsFromService = await setProjectNames(wesProjectsFromService, tenant, locale, logger)
    const allAvailableProducts = await getAllAvailableProducts(tenant, locale, logger)

    const limit = pLimit(15)

    const buildPromises = wesProjectsFromService.map((p) =>
      limit(() => buildProject(p, allAvailableProducts ?? [], !!owner, locale, logger))
    )

    return await Promise.all(buildPromises)
  } catch (error) {
    logger.error('Failed to get wes projects', error as Error)
    throw error
  }
}

export const getRestOfWesProjects = async (
  wesProjectsCount: number,
  tenant: string,
  locale: string,
  owner: string,
  logger: Logger,
  authorizationHeader : string
) => {
  try {
    let wesProjectsFromService = await fetchRestOfWesProjects(wesProjectsCount, tenant, owner, logger, authorizationHeader)
    wesProjectsFromService = patchProductKeys(wesProjectsFromService)
    wesProjectsFromService = await setProjectNames(wesProjectsFromService, tenant, locale, logger)
    const allAvailableProducts = await getAllAvailableProducts(tenant, locale, logger)

    const limit = pLimit(15)

    const buildPromises = wesProjectsFromService.map((p) =>
      limit(() => buildProject(p, allAvailableProducts ?? [], !!owner, locale, logger))
    )

    return await Promise.all(buildPromises)
  } catch (error) {
    logger.error('Failed to get rest of wes projects', error as Error)
    throw error
  }
}

export const getWesProjectsCount = async (tenant: string, owner: string, logger : Logger, authorizationHeader : string) => {
  try {
    return Number(await fetchWesProjectCount(tenant, owner, logger, authorizationHeader))
  } catch (error) {
    logger.error('Failed to get wes projects count', error as Error)
    throw error
  }
}

const generateProductKeyBatches = (wesProjectsFromService: any[]) => {
  const productKeys = wesProjectsFromService
    .map((wesProject) => {
      return wesProject.product.key
    })
    .filter((k) => k)

  const distinctProductKeys = [...new Set(productKeys)]

  return chunkArray(distinctProductKeys, 100)
}

export const rename = async (
  workId: string,
  newName: string,
  tenant: string,
  owner: string,
  logger: Logger,
  authorizationHeader: string
) => {
  try {
    await renameWorkEntity(workId, newName, tenant, owner, authorizationHeader)
  } catch (error) {
    logger.error('Failed to rename wes project', error as Error)
    throw error
  }
}

export const clone = cloneFromUDS
export const deleteWesProject = async (projectId: string, logger: Logger, authorizationHeader : string) => {
  try {
    await deleteWorkEntity(projectId, authorizationHeader)
  } catch (error) {
    logger.error('Failed to delete wes project', error as Error)
    throw error
  }
}

async function cloneFromUDS (
  workId: string,
  tenant: string,
  owner: string,
  logger: Logger,
  authorizationHeader: string,
  newName?: string
) {
  try {
    const workEntityToClone = await fetchWorkEntity(workId, tenant, authorizationHeader)

    delete workEntityToClone.workId
    delete workEntityToClone.workVersionId

    const documentInfo = await cloneDocument(workEntityToClone.design.designUrl, authorizationHeader)

    workEntityToClone.workName = newName
    if (workEntityToClone.design.editUrl) {
      // eslint-disable-next-line no-template-curly-in-string
      workEntityToClone.design.editUrl = workEntityToClone.design.editUrl.replace(workId, '${workId}')
    }
    workEntityToClone.design.documentUrl = documentInfo.url
    workEntityToClone.design.designUrl = documentInfo.documentRevisionUrl
    workEntityToClone.design.displayUrl = documentInfo.previewInstructionSourceUrl
    workEntityToClone.design.manufactureUrl = documentInfo.instructionSourceUrl
    workEntityToClone.resources['parentWork'] = workId

    const newWorkEntity = await createNewWorkEntity(workEntityToClone, workEntityToClone.tenant, owner, authorizationHeader)
    return newWorkEntity
  } catch (error) {
    logger.error('Failed to clone wes project', error as Error)
    throw error
  }
}

const setProjectNames = async (wesProjectsFromService: any[], tenant: string, locale: string, logger: Logger) => {
  if (!wesProjectsFromService?.length) return wesProjectsFromService

  const productKeyBatches = generateProductKeyBatches(wesProjectsFromService)

  let productNames: Record<string, string> = {}

  for (const batch of productKeyBatches) {
    productNames = { ...productNames, ...(await fetchProductNames(tenant, locale, batch, logger)) }
  }

  return wesProjectsFromService.map((wesProject) => ({
    ...wesProject,
    productName: productNames[wesProject.product.key],
  }))
}

const isAffectedByLegacyEditUrlIssue = (locale: string, project: any) => {
  const affectedLocales = ['fr-ch', 'it-ch', 'fr-be']

  return affectedLocales.includes(locale.toLowerCase()) && project?.design?.editUrl?.includes('/studio')
}

const buildProject = async (
  project: any,
  allAvailableProducts: string[],
  isCareAgent: boolean,
  locale: string,
  logger: Logger
): Promise<WesProject> => {
  const isProductAvailable = allAvailableProducts.some((x) => x === project?.product?.key)

  if (project.tenant === 'LOGO-UNPURCHASED-PROD' || project.tenant === 'LOGO-UNPURCHASED-DEV') {
    return new LogoMakerProject(project, isProductAvailable, isCareAgent)
  } else if (project.tenant === 'VISTACREATE-UNP-PROD') {
    return new VistaCreateProject(project, isProductAvailable, isCareAgent)
  }

  try {
    if (isAffectedByLegacyEditUrlIssue(locale, project)) {
      project.design.editUrl = await fetchStudioEditUrl(
        project.product.key,
        project.product.version,
        locale,
        project.workId,
        logger
      )
    }
  } catch (error) {
    logger.error('Failed to get studio edit url for LegacyEditUrlIssue', error as Error, { project })
  }

  return new WesProject(project, isProductAvailable, isCareAgent)
}

const chunkArray = (array: any[], chunkSize: number) => {
  const results = []
  while (array.length) {
    results.push(array.splice(0, chunkSize))
  }
  return results
}

const patchProductKeys = (projects: any[]) => {
  // Teamwear products could have the product key in resources instead of product
  projects.forEach((p) => (p.product.key = p.product.key || p.resources?.productKey))
  return projects
}

import { PreviewModes, PurcsPreview } from '@vp/react-product-preview'
import '@vp/react-product-preview/dist/cjs/index.css'
import { Box } from '@vp/swan'
import { Project } from '../../domain/model/project'
import { LazyLoadingImage } from '../LazyLoadingImage'
import { useIdentity, useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../hooks/useLocalizations'
import { REQUESTOR } from '../../constants'

const PURCS_VISTAPRINT_TENANT = 'vistaprint-prod'
const PURCS_PROMOTIQUE_TENANT = 'promotique-prod'

const getPurcsTenant = (tenant: string) => purcsTenants[tenant] || purcsTenants.default

const purcsTenants: Record<string, string> = {
  vistaprint: PURCS_VISTAPRINT_TENANT,
  promotique: PURCS_PROMOTIQUE_TENANT,
  brandstore: PURCS_PROMOTIQUE_TENANT,
  default: PURCS_VISTAPRINT_TENANT,
}

type ProjectImageProps = {
  project: Project;
  onClick?: () => void;
}

export const ProjectImage = (props: ProjectImageProps) => {
  const { project, onClick } = props
  return (
    <Box style={{ cursor: props.onClick ? 'pointer' : 'default' }}>
      {project.imageUrl
        ? (
          <LazyLoadingImage src={project.imageUrl} alt={project.name} hasBadge={project.isFinished} onClick={onClick} />
          )
        : (
          <PurcsImage data-testid='project-image' project={project} onClick={onClick} />
          )}
    </Box>
  )
}

const PurcsImage = (props: { project: Project; onClick?: () => void }) => {
  const { locale, tenant } = useUserContext()
  const { identity } = useIdentity()
  const { project, onClick } = props

  const { t } = useLocalization()

  return (
    <PurcsPreview
      requestor={REQUESTOR}
      locale={locale}
      tenantId={getPurcsTenant(tenant as string)}
      width={500}
      previewMode={PreviewModes.Carousel}
      optionSelections={undefined}
      workRevisionId={project.workRevisionId}
      authorization={identity?.authorizationHeader}
      coreProductKey={project.productKey}
      productVersion={project.productVersion}
      onClickMethod={onClick}
      accessibleTextForPrevious={t('projectInfo.textForPrev')}
      accessibleTextForNext={t('projectInfo.textForNext')}
      previewInstructionsUri={undefined}
    />
  )
}

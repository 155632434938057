import { Box, useScreenClass, responsive, FlexBox } from '@vp/swan'
import DesignServicesButton from './DesignServicesButton'
import { Project } from '../../domain/model/project'
import { LogoMakerProject } from '../../domain/model/logoMakerProject'
import DeleteButton from './DeleteButton'
import { EditLink } from './Edit'
import CopyButton from './CopyButton'
import { DownloadButton } from './DownloadButton'
import { isButtonsExperienceControlVariation } from '../../utils/buttonsExperienceVariations'
import { ON_DEMAND_GEN_AI_LOGO } from '../../constants'

const ResponsiveFlexBox = responsive(FlexBox)

interface ProjectButtonsProps {
  project: Project;
  buttonsExperienceVariation: string;
}

const ProjectActions = (props: ProjectButtonsProps) => {
  const { project, buttonsExperienceVariation } = props

  const isControl = isButtonsExperienceControlVariation(buttonsExperienceVariation)

  const screenSize = useScreenClass()
  const isSmallDevice = screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md'
  const isGenAiGeneratedLogo = props.project instanceof LogoMakerProject && props.project.logoSource === ON_DEMAND_GEN_AI_LOGO

  return (
    <>
      {isSmallDevice && (
        <ResponsiveFlexBox
          padding='4'
          gap='5'
          xs={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
          lg={{ flexDirection: 'column' }}
        >
          {isControl && !isGenAiGeneratedLogo && project.editUrl && <EditLink project={project} />}
          {project.hasCopy && <CopyButton project={project} />}
          {project.isFinished && project instanceof LogoMakerProject && <DownloadButton project={project} />}
          {project.canBeDeleted && <DeleteButton project={project} />}
          <DesignServicesButton project={project} />
        </ResponsiveFlexBox>
      )}
      {!isSmallDevice && (
        <>
          {isControl && !isGenAiGeneratedLogo && project.editUrl && (
            <Box>
              <EditLink project={project} />
            </Box>
          )}
          {project.hasCopy && (
            <Box>
              <CopyButton project={project} />
            </Box>
          )}
          {project.isFinished && project instanceof LogoMakerProject && (
            <Box>
              <DownloadButton project={project} />
            </Box>
          )}
          {project.canBeDeleted && (
            <Box>
              <DeleteButton project={project} />
            </Box>
          )}
          <Box>
            <DesignServicesButton project={project} />
          </Box>
        </>
      )}
    </>
  )
}

export default ProjectActions

export const scrollToTop = (height: number) => {
  window.scrollTo({
    top: height,
    left: 0,
    behavior: 'smooth',
  })
}

export const navigateToInternalLink = (link: string) => {
  const element = document.getElementById(link)
  if (element && typeof element.scrollIntoView === 'function') {
    element.scrollIntoView({ behavior: 'smooth' })
  } else {
    console.warn(`Element with id ${link} not found or scrollIntoView is not a function`)
  }
}

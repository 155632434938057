const BASE_URL = 'https://vpmerchant.dsp.dst.vpsvc.com/v1'

type Price = {
  listPrice: number;
  discountedPrice: number;
  currency: string;
}

type ServiceConfig = {
  [key: string]: {
    serviceName: string;
    status: string;
    slaHours: number;
    price?: Price;
  };
}

export type CalloutData = {
  serviceConfig: ServiceConfig;
}

export async function fetchCalloutData (
  mpvId: string,
  culture: string,
  authorizationHeader: string
): Promise<CalloutData> {
  const response = await fetch(`${BASE_URL}/Callout/${culture}?mpvId=${mpvId}`, {
    method: 'GET',
    headers: {
      Authorization: authorizationHeader,
      'Content-Type': 'application/json'
    }
  })

  if (!response.ok) {
    throw new Error(`Error fetching callout data: ${response.statusText}`)
  }

  const data = await response.json()
  return data as CalloutData
}

import { Popover, PopoverContent, PopoverLauncher } from '@vp/swan'
import { useLocalization } from '../../hooks/useLocalizations'

export const withPopover = (Component: React.ReactNode, popoverContentKey: string) => {
  const { t } = useLocalization()
  const popoverContent = t(popoverContentKey)
  return (
    <Popover>
      <PopoverLauncher>{Component}</PopoverLauncher>
      <PopoverContent data-testid='popover-content'>{popoverContent}</PopoverContent>
    </Popover>
  )
}

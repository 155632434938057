import { REQUESTOR } from '../constants'

export type PricingServiceResponse = {
  currency: string;
  listPrice: {
    taxed: number;
    untaxed: number;
  };
  discountedPrice: {
    taxed: number;
    untaxed: number;
  };
  fractionDigits: number;
}

const endpoint = 'https://product-catalog-pricing.cdn.vpsvc.com/v4/prices/startingAt/estimated'

export const getPrice = async (
  productKey: string,
  quantity: number,
  selections: Record<string, string>,
  priceContext: string,
  productVersion?: number
) => {
  if (!quantity) {
    throw new Error(`cannot get pricing with invalid quantity: ${quantity}`)
  }

  const url = new URL(endpoint)
  url.searchParams.set('requestor', REQUESTOR)
  url.searchParams.set('productKey', productKey)
  url.searchParams.set('OptionalPriceComponents', 'UnitPrice')
  url.searchParams.set('quantities', quantity.toString())
  productVersion && url.searchParams.set('productVersion', productVersion?.toString())

  const selectedOptions = Object.keys(selections)
  for (let i = 0; i < selectedOptions.length; i++) {
    url.searchParams.set(`selections[${selectedOptions[i]}]`, selections[selectedOptions[i]])
  }

  const requestUrl = `${url.toString()}&${priceContext}`

  const response = await fetch(requestUrl)
  if (!response.ok) {
    throw new Error(`Failed to fetch pricing: ${response.statusText}`)
  }

  const data = await response.json()

  const price = data.estimatedPrices[quantity]

  return {
    currency: data.currency,
    listPrice: price.totalListPrice,
    discountedPrice: price.totalDiscountedPrice,
    fractionDigits: data.fractionDigits,
  } as PricingServiceResponse
}

import { List, ListItem, Typography } from '@vp/swan'
import { useLocalization } from '../../hooks/useLocalizations'

const NoSearchResultsTips = () => {
  const { t } = useLocalization()
  return (
    <>
      <Typography fontSkin='body-standard-bold' mb='3'>
        {t('search.searchTips')}
      </Typography>
      <List marginBottom='7' skin='standard'>
        <ListItem>{t('search.checkSpelling')}</ListItem>
        <ListItem>{t('search.similarWords')}</ListItem>
        <ListItem>{t('search.includeProductName')}</ListItem>
      </List>
    </>
  )
}

export default NoSearchResultsTips

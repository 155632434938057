import {
  ModalDialog,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogFooter,
  ModalDialogCloseButton,
  ModalDialogNav,
} from '@vp/swan'
import { Project } from '../../domain/model/project'
import { ProjectImage } from './ProjectImage'
import { EditProjectButton } from '../../components/actionButtons/Edit/EditProjectButton'
import AddToCartButton from '../addToCart/AddToCartButton'
import { useContext } from 'react'
import { AlertContext } from '../../context/AlertContext'
import { useLocalization } from '../../hooks/useLocalizations'
import { ON_DEMAND_GEN_AI_LOGO } from '../../constants'
import { LogoMakerProject } from '../../domain/model/logoMakerProject'
import { DownloadButton } from '../actionButtons/DownloadButton'

type NameInputModalProps = {
  project: Project;
  isOpen: boolean;
  onClose: () => void;
}

const ProjectDetailsModal = (props: NameInputModalProps) => {
  const { t } = useLocalization()
  const { showAlert } = useContext(AlertContext)
  return (
    <ModalDialog
      data-testid='project-details-modal'
      isOpen={props.isOpen}
      onRequestDismiss={props.onClose}
      variant='panel-right'
      bodyWidth='capped'
      onlyRenderWhenOpen
    >
      <ModalDialogContent aria-label={props.project.name}>
        <ModalDialogNav>
          <ModalDialogCloseButton accessibleText={t('projectDetailsModal.close')} />
        </ModalDialogNav>
        <ModalDialogHeader>{props.project.name}</ModalDialogHeader>
        <ModalDialogBody>
          <ProjectImage project={props.project} />
        </ModalDialogBody>
        <ModalDialogFooter pinned>
          <ModalDialogButtons>
            <AddToCartButton
              project={props.project}
              buttonProps={{
                width: 'full-width' as 'standard' | 'full-width',
                skin: 'secondary',
              }}
              onError={() => showAlert(t('addToCartError'))}
            >
              {t('addToCartButton')}
            </AddToCartButton>
            <EditProjectButton width='full-width' skin='primary' project={props.project} />
            {props.project.isFinished &&
              props.project instanceof LogoMakerProject &&
              props.project.logoSource === ON_DEMAND_GEN_AI_LOGO && (
                <DownloadButton project={props.project} width='full-width' />
            )}
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default ProjectDetailsModal

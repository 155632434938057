import { FlexBox, Typography, responsive, useScreenClass } from '@vp/swan'
import AddToCartButton from './addToCart/AddToCartButton'
import { useContext } from 'react'
import Price from './Price'
import { SpinnerButtonProps } from './SpinnerButton'
import {
  isButtonsExperienceControlVariation,
  isButtonsExperienceV1Variation,
  isButtonsExperienceV2Variation,
} from '../utils/buttonsExperienceVariations'
import { useLocalization } from '../hooks/useLocalizations'
import { Project } from '../domain/model/project'
import { AlertContext } from '../context/AlertContext'
import { EditProjectButton } from './actionButtons/Edit/EditProjectButton'

const ResponsiveFlexBox = responsive(FlexBox)

interface ProjectButtonsProps {
  project: Project;
  buttonsExperienceVariation: string;
}

const ProjectButtons = (props: ProjectButtonsProps) => {
  const { project, buttonsExperienceVariation } = props

  const { showAlert } = useContext(AlertContext)
  const { t } = useLocalization()

  const screenSize = useScreenClass()
  const isMobileOrTablet = screenSize === 'xs' || screenSize === 'sm'

  const isVariationV1 = isButtonsExperienceV1Variation(buttonsExperienceVariation)
  const isVariationV2 = isButtonsExperienceV2Variation(buttonsExperienceVariation)
  const isControl = isButtonsExperienceControlVariation(buttonsExperienceVariation)

  const addToCartButtonProps: Partial<SpinnerButtonProps> = isVariationV2 ? { skin: 'secondary' } : {}

  return (
    <ResponsiveFlexBox
      xs={{ flexDirection: 'column', style: { gap: '15px' } }}
      md={{ flexDirection: 'row', style: { gap: '0' } }}
      alignItems='center'
      mt={isControl ? 'to-actions' : '0'}
    >
      <>
        {isControl && project.canReorder && (
          <>
            <AddToCartButton project={project} onError={() => showAlert(t('addToCartError'))}>
              {t('addToCartButton')}
            </AddToCartButton>
            <ResponsiveFlexBox
              xs={{ ml: '0', mt: 'between-actions' }}
              md={{ ml: 'to-actions', mt: '0' }}
              data-testid='price-info'
            >
              {project.isMerchandisingAvailable
                ? (
                  <Price project={project} buttonsExperienceVariation={buttonsExperienceVariation} />
                  )
                : (
                  <Typography fontSkin='body-standard' textColor='error'>
                    {t('outOfStock')}
                  </Typography>
                  )}
            </ResponsiveFlexBox>
          </>
        )}
        {isVariationV1 && (
          <>
            {project.canReorder && !project.isMerchandisingAvailable && isMobileOrTablet && (
              <ResponsiveFlexBox
                xs={{ ml: '0', mb: 'between-actions' }}
                md={{ ml: 'to-actions', mb: '0' }}
                data-testid='price-info'
              >
                <Typography fontSkin='body-standard' textColor='error'>
                  {t('outOfStock')}
                </Typography>
              </ResponsiveFlexBox>
            )}
            {project.canReorder && (
              <AddToCartButton project={project} onError={() => showAlert(t('addToCartError'))}>
                {t('addToCartButton')}
              </AddToCartButton>
            )}
            <EditProjectButton
              project={project}
              skin='secondary'
              width={isMobileOrTablet ? 'full-width' : 'standard'}
              ml={isMobileOrTablet ? '0' : project.canReorder ? 'between-actions' : '0'}
              mt={isMobileOrTablet ? (project.canReorder ? 'between-actions' : '0') : '0'}
            />
            {project.canReorder && !project.isMerchandisingAvailable && !isMobileOrTablet && (
              <ResponsiveFlexBox
                xs={{ ml: '0', mb: 'between-actions' }}
                md={{ ml: 'to-actions', mb: '0' }}
                data-testid='price-info'
              >
                <Typography fontSkin='body-standard' textColor='error'>
                  {t('outOfStock')}
                </Typography>
              </ResponsiveFlexBox>
            )}
          </>
        )}
        {isVariationV2 && (
          <>
            <EditProjectButton
              project={project}
              skin='primary'
              width={isMobileOrTablet ? 'full-width' : 'standard'}
              mr={isMobileOrTablet ? '0' : project.canReorder ? 'between-actions' : '0'}
              mb={isMobileOrTablet ? (project.canReorder ? 'between-actions' : '0') : '0'}
            />
            {project.canReorder && (
              <AddToCartButton
                project={project}
                buttonProps={addToCartButtonProps}
                onError={() => showAlert(t('addToCartError'))}
              >
                {t('addToCartButton')}
              </AddToCartButton>
            )}
            {project.canReorder && !project.isMerchandisingAvailable && (
              <ResponsiveFlexBox
                xs={{ ml: '0', mt: 'between-actions' }}
                md={{ ml: 'to-actions', mt: '0' }}
                data-testid='price-info'
              >
                <Typography fontSkin='body-standard' textColor='error'>
                  {t('outOfStock')}
                </Typography>
              </ResponsiveFlexBox>
            )}
          </>
        )}
      </>
    </ResponsiveFlexBox>
  )
}

export default ProjectButtons

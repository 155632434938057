import { Box, FlexBox, Link, Typography, useScreenClass } from '@vp/swan'
import { NotSignedInIcon } from '../../icons/icon-notSignedIn'
import NoProjectsRecommendations from './NoProjectsRecommendations'
import { useLocalization } from '../../hooks/useLocalizations'
import { useIdentity } from '@vp/ubik-context'

const NotSignedIn = () => {
  const { t } = useLocalization()
  const { auth } = useIdentity()

  const screenSize = useScreenClass()
  const isSmallScreen = screenSize === 'xs' || screenSize === 'sm'

  const applyPaddingToBigScreen = isSmallScreen ? {} : { padding: '0 15vw' }

  const notSignedInText = t('noProjects.notSignedInText')
  const goToSignIn = t('noProjects.signInLink')

  return (
    <FlexBox marginY='6' style={{ width: '100%' }} flexDirection='column' alignItems='center'>
      <Box style={{ height: '200px', width: '200px' }}>
        <NotSignedInIcon />
      </Box>
      <Typography textAlign='center' fontSkin='body-standard-bold' style={applyPaddingToBigScreen}>
        {`${notSignedInText} `}
        <Link data-testid='sign-in-link' onClick={() => auth?.signIn()}>
          {goToSignIn}
        </Link>
      </Typography>
      <NoProjectsRecommendations />
    </FlexBox>
  )
}

export default NotSignedIn

import { Logger } from '@vp/ubik-logging'
import { REQUESTOR } from '../constants'

export const fetchDesignDashboardUrl = async (tenant: string, locale: string, authorizationHeader: string, logger: Logger) => {
  let url = ''
  try {
    const response = await fetch(
      `https://url.prod.merch.vpsvc.com/v3/all/${tenant}/${locale}?pageType=myaccount&requestor=${REQUESTOR}`
      , { headers: { Authorization: authorizationHeader, 'Content-Type': 'application/json' } })

    const result = await response.json() as Record<string, any>
    url = result['ma:myDesignServices'].url
  } catch (error) {
    logger.error('Failed to fetch design dashboard url', error as Error)
    url = ''
  }

  return url
}

export const fetchUrlById = async (tenant: string, locale: string, id: string, authorizationHeader: string, logger: Logger) => {
  let url = ''

  try {
    const response = await fetch(
      `https://url.prod.merch.vpsvc.com/v3/url/${tenant}/${locale}/${id}?requestor=${REQUESTOR}`
      , { headers: { Authorization: authorizationHeader, 'Content-Type': 'application/json' } })

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const result = await response.json() as any
    url = result.url
  } catch (error) {
    logger.error('Failed to fetch url by id', error as Error, { id })
    url = ''
  }

  return url
}

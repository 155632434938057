import { deleteWesProject } from '../services/wesProjectsService'
import { deleteProject as deleteAlbelliProject } from '../services/albelliProjectService'
import { AlbelliProject } from '../model/albelliProject'
import { Project } from '../model/project'
import { WesProject } from '../model/wesProject'
import { LogoMakerProject } from '../model/logoMakerProject'
import { deleteProject as deleteLogo } from '../services/logoMakerProjectService'
import { Logger } from '@vp/ubik-logging'

export const deleteProject = async (project: Project, authorizationHeader: string, logger: Logger) => {
  if (project instanceof LogoMakerProject) {
    const deleteWesPromise = deleteWesProject(project.id, logger, authorizationHeader)
    const deleteLogoPromise = deleteLogo(project, authorizationHeader)
    await Promise.all([deleteWesPromise, deleteLogoPromise])
  } else if (project instanceof WesProject) {
    await deleteWesProject(project.id, logger, authorizationHeader)
  } else if (project instanceof AlbelliProject) {
    await deleteAlbelliProject(project.id, authorizationHeader)
  } else {
    logger.error(
      'Unknown project type for commands. You need to add all the project types to all the commands (deleting projects)',
      new Error('Unknown project type for commands (deleting projects).'),
      { project }
    )
    throw new Error('Unknown project type for commands.')
  }
}

import { REQUESTOR } from '../constants'
import { Logger } from '@vp/ubik-logging'

export const fetchStudioEditUrl = async (
  productKey: string,
  productVersion: number,
  locale: string,
  workId: string,
  logger: Logger
) => {
  const url = new URL('https://studio-available-routes-service.design.vpsvc.com/v1/studioRouting/studio')
  url.searchParams.append('productKey', productKey)
  url.searchParams.append('productVersion', productVersion.toString())
  url.searchParams.append('locale', locale)
  url.searchParams.append('designOptionType', 'work')
  url.searchParams.append('designOptionValue', workId)
  url.searchParams.append('requestor', REQUESTOR)

  try {
    const response = await fetch(url.toString())
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const result = await response.json() as any

    return result.url.href
  } catch (error) {
    logger.error('Failed to fetch studio edit url', error as Error, { productKey, productVersion, workId })
    return ''
  }
}

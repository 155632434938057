import { Typography } from '@vp/swan'
import NoSearchResultsTips from './NoSearchResultsTips'
import { useLocalization } from '../../hooks/useLocalizations'

type SearchResultsProps = {
  searchTerm: string;
  isLoading: boolean;
  filteredProjectsCount: number;
}

const SearchResults = (props: SearchResultsProps) => {
  const { t } = useLocalization()
  return (
    <>
      <Typography marginTop={10} fontSkin='body-standard-bold'>
        {t('search.resultsFor', { searchTerm: props.searchTerm })}
      </Typography>

      {!props.filteredProjectsCount && !props.isLoading && (
        <>
          <Typography mt='3' mb='7'>
            {t('search.noResultFound')}
          </Typography>
          <NoSearchResultsTips />
        </>
      )}
    </>
  )
}

export default SearchResults

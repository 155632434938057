export const DesignHelpIcon = () => (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.5 13.4583L13.4583 8.5L15.5833 10.625L10.625 15.5833L8.5 13.4583Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.7503 9.20964L11.6878 3.89714L1.41699 1.41797L3.89616 11.6888L9.20866 12.7513L12.7503 9.20964Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.41699 1.41797L6.79041 6.79139'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.79167 9.20833C8.57407 9.20833 9.20833 8.57407 9.20833 7.79167C9.20833 7.00926 8.57407 6.375 7.79167 6.375C7.00926 6.375 6.375 7.00926 6.375 7.79167C6.375 8.57407 7.00926 9.20833 7.79167 9.20833Z'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

import { Typography } from '@vp/swan'
import { useRelativeTime } from '../../hooks/useRelativeTime'
import { useLocalization } from '../../hooks/useLocalizations'

type EditedTimeProps = {
  editDate: Date;
}

export const EditedTime = (props: EditedTimeProps) => {
  const { t } = useLocalization()

  const timeAgo = useRelativeTime(props.editDate)

  if (timeAgo === null) {
    return <></>
  }

  const editedTimeAgo = t('projectInfo.editedTimeAgo', { timeAgo })

  return <Typography>{editedTimeAgo}</Typography>
}

import { ErrorInfo, useContext, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Box,
  FlexBox,
  Link,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileImage,
  StandardTileName,
  StandardTileOverlay,
  StandardTileSeparatelyClickableContents,
} from '@vp/swan'
import { useLogger } from '@vp/ubik-context'
import { Project } from '../../domain/model/project'
import { EditButton } from '../actionButtons/Edit'
import { trackProjectEvent, getDesignHelpEventLabel } from '../Analytics/trackingUtils'
import { ProjectTileDetailsModal } from './ProjectTileDetailsModal'
import { EditedTime } from './EditedTime'
import { SingleProjectImage } from './images/SingleProjectImage'
import { ProjectTileActionMenu } from './actionMenu/ProjectTileActionMenu'
import { OutOfStockCallout } from './components/Callouts/OutOfStockCallout'
import { AlertContext } from '../../context/AlertContext'
import { useTracking } from '../../context/TrackingContext'
import { useDesignServicesWrapper } from '../../hooks/useDesignServicesWrapper'
import { useLocalization } from '../../hooks/useLocalizations'
import { AddToCartButtonWrapper } from '../addToCart/AddToCartButtonWrapper'

import './ProjectTile.css'

type ProjectTileProps = {
  project: Project;
}

export const ProjectTile = ({ project }: ProjectTileProps) => {
  const { t } = useLocalization()
  const { showAlert } = useContext(AlertContext)
  const { labels } = useTracking()
  const logger = useLogger()

  const [isProjectTileDetailsModalOpen, setIsProjectTileDetailsModalOpen] = useState(false)

  const openProjectTileDetailsModal = () => {
    trackProjectEvent(labels.OPEN_PROJECT_DETAILS_MODAL, project)
    setIsProjectTileDetailsModalOpen(true)
  }

  const closeProjectTileDetailsModal = () => {
    setIsProjectTileDetailsModalOpen(false)
  }

  const isOutOfStock = project.canReorder && !project.isMerchandisingAvailable

  const onModalError = (error: Error, errorInfo: ErrorInfo) => {
    showAlert(t('errorLoadingProjects'))
    logger.error('rendering project modal failed', error, { errorInfo, project })
  }

  return (
    <>
      <ErrorBoundary fallback={<></>} onError={onModalError}>
        <ProjectTileDetailsModal
          project={project}
          isOpen={isProjectTileDetailsModalOpen}
          onClose={closeProjectTileDetailsModal}
        />
      </ErrorBoundary>

      <StandardTile layout='vertical' imageWidth='standard' skin='product' bgc='standard'>
        <StandardTileSeparatelyClickableContents>
          <StandardTileOverlay>
            <ProjectTileOverlayLayout>
              <ProjectTileStatusStack>{isOutOfStock && <OutOfStockCallout />}</ProjectTileStatusStack>
              <ProjectTileActionMenu project={project} />
            </ProjectTileOverlayLayout>
          </StandardTileOverlay>
        </StandardTileSeparatelyClickableContents>
        <StandardTileImage>
          <SingleProjectImage project={project} />
        </StandardTileImage>
        <StandardTileContents>
          <Link onClick={openProjectTileDetailsModal} covering skin='unstyled'>
            <StandardTileName className='project-heading'>{project.name}</StandardTileName>
          </Link>
          <StandardTileDescription>
            <EditedTime editDate={project.modified} />
          </StandardTileDescription>
          <StandardTileSeparatelyClickableContents>
            <Box paddingTop='3'>
              <DesignServicesCta project={project} />
            </Box>
            <EditButton
              project={project}
              skin='primary'
              size='mini'
              width='full-width'
              onClick={() => trackProjectEvent(labels.EDIT, project)}
            >
              {t('actionButtons.edit')}
            </EditButton>
            <AddToCartButtonWrapper project={project} width='full-width' onError={() => showAlert(t('addToCartError'))}>
              {t('actionButtons.addToCart')}
            </AddToCartButtonWrapper>
          </StandardTileSeparatelyClickableContents>
        </StandardTileContents>
      </StandardTile>
    </>
  )
}

export const ProjectTileOverlayLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <FlexBox alignItems='flex-start' justifyContent='space-between' style={{ width: '100%' }}>
      {children}
    </FlexBox>
  )
}

const ProjectTileStatusStack = ({ children }: { children: React.ReactNode }) => {
  return (
    <FlexBox gap='3' flexWrap='wrap'>
      {children}
    </FlexBox>
  )
}

const DesignServicesCta = ({ project }: { project: Project }) => {
  const { labels } = useTracking()
  const { templateEditsEnabled, templateEditsBriefUrl, needDesignHelpText, designHelpAction } =
    useDesignServicesWrapper(project)

  if (!templateEditsEnabled) {
    return <> </>
  }

  return (
    <Link
      withIcon
      skin='cta'
      href={templateEditsBriefUrl}
      onClick={() => designHelpAction && trackProjectEvent(getDesignHelpEventLabel(designHelpAction, labels), project)}
    >
      {needDesignHelpText}
    </Link>
  )
}

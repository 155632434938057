import { FlexBoxProps, FlexBox } from '@vp/swan'

export const PromoBar = (props: FlexBoxProps) => (
  <FlexBox
    fontSkin='body-small'
    p='3'
    style={{ minHeight: 60 }}
    alignItems='center'
    justifyContent='center'
    gap='2'
    {...props}
  />
)

import { Logger } from '@vp/ubik-logging'
import { deleteLogo, renameLogo } from '../../clients/logoMaker.proxy'
import { LogoMakerProject } from '../model/logoMakerProject'

export const deleteProject = async (project: LogoMakerProject, authorizationHeader:string) =>
  await deleteLogo(project.logoId, project.tenant, authorizationHeader)

export const renameProject = async (project: LogoMakerProject, newName: string, logger: Logger, authorizationHeader : string) => {
  try {
    newName = newName || project.name
    await renameLogo(project.logoId, newName, project.tenant, authorizationHeader)
  } catch (error) {
    logger.error('Failed to rename wes project', error as Error)
    throw error
  }
}

export const NotSignedInIcon = () => (
  <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='11.664' cy='7.874' r='4.374' stroke='#E1ECEF' strokeWidth='1.5' />
    <path
      d='M4 19.5c0-.953.198-1.896.583-2.776a7.24 7.24 0 0 1 1.662-2.352A7.708 7.708 0 0 1 8.73 12.8a8.045 8.045 0 0 1 2.933-.552c1.007 0 2.003.188 2.933.552.93.364 1.775.899 2.486 1.572a7.238 7.238 0 0 1 1.662 2.352 6.92 6.92 0 0 1 .583 2.776'
      stroke='#E1ECEF'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

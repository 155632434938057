import { AlertBox, AlertBoxDismissButton, Typography } from '@vp/swan'
import { useLocalization } from '../hooks/useLocalizations'
const Alert = (props: { message: string; isOpened: boolean; onDismiss: () => void }) => {
  const { message, isOpened, onDismiss } = props
  const { t } = useLocalization()

  return (
    <AlertBox
      style={{ position: 'sticky', zIndex: '10005' }}
      mb='5'
      skin='error'
      dismissed={!isOpened}
      onRequestDismiss={onDismiss}
      toast
      pt='5'
    >
      <Typography>{message?.toString()}</Typography>
      <AlertBoxDismissButton visuallyHiddenLabel={t('dismissAlert')} />
    </AlertBox>
  )
}

export default Alert

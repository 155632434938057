import { Button, ButtonProps, Icon, Link } from '@vp/swan'
import { Project } from '../../../domain/model/project'
import { LogoMakerProject } from '../../../domain/model/logoMakerProject'
import { trackProjectEvent } from '../../Analytics/trackingUtils'
import { useLocalization } from '../../../hooks/useLocalizations'
import { withPopover } from '../PopoverWrapper'
import {
  LOGO_STATUS,
  ON_DEMAND_GEN_AI_LOGO,
  TrackingEventLabels,
} from '../../../constants'

interface EditProjectButtonProps extends ButtonProps {
  project: Pick<Project, 'editUrl' | 'id' | 'trackingKey'>;
}

export const EditProjectButton = (props: EditProjectButtonProps) => {
  const { project, ...buttonProps } = props
  const { editUrl } = project
  const { t } = useLocalization()

  const onEditProjectClick = () => {
    sessionStorage.removeItem('isRedirectedFromProjectPage')
    trackProjectEvent(TrackingEventLabels.EDIT, { id: project.id, trackingKey: project.trackingKey })
    window.location.assign(editUrl)
  }
  const isLogoOrderProcessing =
    props.project instanceof LogoMakerProject && props.project.logoStatus === LOGO_STATUS.LOGO_ORDER_PROCESSING
  const isGenAiGeneratedLogo =
    props.project instanceof LogoMakerProject && props.project.logoSource === ON_DEMAND_GEN_AI_LOGO

  const EditProjectButtonComponent = () => {
    if (isGenAiGeneratedLogo) return <></>
    return (
      <Button
        iconPosition='left'
        {...buttonProps}
        render={
          isLogoOrderProcessing
            ? undefined
            : (props: any) => {
                if (!editUrl) {
                  return <Button {...props} disabled />
                }
                return <Link onClick={onEditProjectClick} {...props} />
              }
        }
      >
        <Icon iconType='edit' skin='standard' />
        {t('actionButtons.editProject')}
      </Button>
    )
  }

  if (isLogoOrderProcessing) {
    return withPopover(EditProjectButtonComponent(), 'popOverContent.downloadOrEditButtonDisabled')
  }

  return EditProjectButtonComponent()
}

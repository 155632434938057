import { Button, ButtonProps, Link } from '@vp/swan'
import { Project } from '../../../domain/model/project'

interface EditButtonProps extends ButtonProps {
  project: Pick<Project, 'editUrl'>;
}

export const EditButton = ({ project, ...buttonProps }: EditButtonProps) => {
  return (
    <Button
      {...buttonProps}
      render={(props : any) => {
        if (!project.editUrl) {
          return <Button {...props} disabled />
        }
        return <Link href={project.editUrl} {...props} />
      }}
    />
  )
}

import React from 'react'
import { TrackingEventLabels } from '../constants'

type TrackingContextType = {
  labels: {
    ADD_TO_CART: TrackingEventLabels;
    DELETE: TrackingEventLabels;
    EDIT: TrackingEventLabels;
    RENAME: TrackingEventLabels;
    COPY: TrackingEventLabels;
    DESIGN_HELP: TrackingEventLabels;
    REQUEST_A_REVISION: TrackingEventLabels;
    VIEW_DESIGN: TrackingEventLabels;
    SEARCH: TrackingEventLabels;
    LEGACY_IMPORT: TrackingEventLabels;
    LEGACY_DELETE: TrackingEventLabels;
    OPEN_PROJECT_DETAILS_MODAL: TrackingEventLabels;
  };
}

const defaultLabels: TrackingContextType['labels'] = {
  ADD_TO_CART: TrackingEventLabels.ADD_TO_CART,
  DELETE: TrackingEventLabels.DELETE,
  EDIT: TrackingEventLabels.EDIT,
  RENAME: TrackingEventLabels.RENAME,
  COPY: TrackingEventLabels.COPY,
  DESIGN_HELP: TrackingEventLabels.DESIGN_HELP,
  REQUEST_A_REVISION: TrackingEventLabels.REQUEST_A_REVISION,
  VIEW_DESIGN: TrackingEventLabels.VIEW_DESIGN,
  SEARCH: TrackingEventLabels.SEARCH,
  LEGACY_IMPORT: TrackingEventLabels.LEGACY_IMPORT,
  LEGACY_DELETE: TrackingEventLabels.LEGACY_DELETE,
  OPEN_PROJECT_DETAILS_MODAL: TrackingEventLabels.OPEN_PROJECT_DETAILS_MODAL,
}
export const TrackingContext = React.createContext<TrackingContextType>({
  labels: defaultLabels,
})

type TrackingContextProviderProps = {
  labels: Partial<TrackingContextType['labels']>;
  children: React.ReactNode;
}
export const TrackingContextProvider = ({ labels, children }: TrackingContextProviderProps) => {
  return (
    <TrackingContext.Provider value={{ labels: { ...defaultLabels, ...labels } }}>{children}</TrackingContext.Provider>
  )
}

export const useTracking = () => {
  return React.useContext(TrackingContext)
}

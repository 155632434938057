import { REQUESTOR } from '../constants'

const localCache: any = {}

export async function getNextStep (tenant: string, locale: string, productKey: string, productVersion: string) {
  const KEY = `${productKey}-${productVersion}`

  if (localCache[KEY]) {
    return localCache[KEY]
  }

  const response = await fetch(
    `https://siteflow.merch.vpsvc.com/v2/action/${tenant}/${encodeURIComponent(
      locale
    )}/addToCart/${productKey}/${productVersion}?requestor=${REQUESTOR}`
  )

  if (!response.ok) {
    throw new Error(`Failed to fetch next step. Status: ${response.status}`)
  }

  const result = await response.json()
  localCache[KEY] = result?.url
  return localCache[KEY]
}

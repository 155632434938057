import { useEffect, useState } from 'react'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { DesignHelpAction, DesignServicesInfo, useDesignServices } from '@vp/dst-my-project-placement'
import { Project } from '../domain/model/project'
import { getMpvIdFromProductKey } from '../domain/services/msxService'
import { getDesignableProductStatus } from '../domain/services/vpmerchantService'
import { useLocalization } from './useLocalizations'

export function useDesignServicesWrapper (project: Project): DesignServicesInfo {
  const { tenant, locale } = useUserContext()
  const { identity } = useIdentity()
  const logger = useLogger()
  const { templateEditsEnabled, templateEditsBriefUrl, needDesignHelpText, designHelpAction } = useDesignServices(
    project.toDesignServicesProject(),
    {
      productKey: project.productKey,
      isProductAvailable: project.isMerchandisingAvailable,
    }
  )
  const [mpvId, setMpvId] = useState('')
  const [isDesignable, setIsDesignable] = useState(false)
  const { t } = useLocalization()
  const standardWithWorkEntityUrl = `/design/briefs/create/care-design?workId=${project.id}`
  const isDesignHelp = templateEditsEnabled && designHelpAction === DesignHelpAction.DESIGN_HELP

  useEffect(() => {
    (async () => {
      if (isDesignHelp) {
        const mpvId = await getMpvIdFromProductKey(tenant as string, locale, project.productKey, logger)

        if (mpvId) {
          const productStatus = await getDesignableProductStatus(mpvId, locale, identity?.authorizationHeader ?? '')
          setIsDesignable(productStatus === 'Available')
        }

        setMpvId(mpvId ?? '')
      }
    })()
  }, [locale, isDesignHelp, project.productKey, tenant])

  // For 'view design', and 'request revision', just
  // return values from useDesignServices hook
  if (!isDesignHelp) {
    return {
      templateEditsEnabled,
      templateEditsBriefUrl,
      needDesignHelpText,
      designHelpAction,
    }
  }

  // Before mpvId is fetched from MSX, don't show the design help CTA
  if (mpvId === '') {
    return {
      templateEditsEnabled: false,
      templateEditsBriefUrl,
      needDesignHelpText,
      designHelpAction,
    }
  }

  // For design help, we override values here. Could be updated in dst-my-project-placement
  // package, but previously was not due to time limitations.
  return {
    templateEditsEnabled: isDesignable,
    templateEditsBriefUrl: `${standardWithWorkEntityUrl}&mpvId=${mpvId}`,
    needDesignHelpText: t('workWithADesigner'),
    designHelpAction: DesignHelpAction.DESIGN_HELP,
  }
}

import { Icon, Button, Link } from '@vp/swan'
import { LogoMakerProject } from '../../../domain/model/logoMakerProject'
import { withPopover } from '../PopoverWrapper'
import { useLocalization } from '../../../hooks/useLocalizations'
import { Project } from '../../../domain/model/project'
import { LOGO_STATUS, ON_DEMAND_GEN_AI_LOGO, TrackingEventLabels } from '../../../constants'
import { trackProjectEvent } from '../../Analytics/trackingUtils'

export const EditLink = (props: { project: Project }) => {
  const { t } = useLocalization()

  const isLogoOrderProcessing =
    props.project instanceof LogoMakerProject && props.project.logoStatus === LOGO_STATUS.LOGO_ORDER_PROCESSING
  const isGenAiGeneratedLogo =
    props.project instanceof LogoMakerProject && props.project.logoSource === ON_DEMAND_GEN_AI_LOGO

  const onEditClickHandler = () => {
    sessionStorage.removeItem('isRedirectedFromProjectPage')
    trackProjectEvent(TrackingEventLabels.EDIT, props.project)
    window.location.assign(props.project.editUrl)
  }

  const EditLinkComponent = () => {
    if (isGenAiGeneratedLogo) {
      return (
        <Link render={(p, ref) => <Button ref={ref} skin='link' className={p.className} {...p} />}>
          {t('actionButtons.nonEditableLogo')}
        </Link>
      )
    }
    return (
      <Button
        iconPosition='left'
        skin='tertiary'
        width='standard'
        onClick={isLogoOrderProcessing ? undefined : onEditClickHandler}
        data-testid='edit-link-component'
      >
        <Icon iconType='edit' size='20p' mr='3' />
        {t('actionButtons.edit')}
      </Button>
    )
  }

  if (isLogoOrderProcessing) {
    return withPopover(EditLinkComponent(), t('popOverContent.downloadOrEditButtonDisabled'))
  }

  if (isGenAiGeneratedLogo) {
    return withPopover(EditLinkComponent(), `${t('popOverContent.nonEditableLogoDescription')}`)
  }

  return EditLinkComponent()
}

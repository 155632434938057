const PRODUCT_STATUS_SERVICE_DOMAIN = 'https://merchant-orchestration-api.products.cimpress.io/v3'

export type ProductStatus = {
  productId: string;
  version: number;
  status: ProductState;
  contextSelections: {
    Merchant: string;
    Country: string;
  };
}

export type ProductState = 'RETIRED' | 'ACTIVE' | 'ARCHIVED' | 'CURRENT' | 'OPEN' | 'ERROR'
export const fetchProductStatus = async (
  productKey: string,
  productVersion: number,
  tenant: string,
  culture: string,
  authorizationHeader: string
) => {
  let productStatus: ProductStatus
  try {
    const response = await fetch(
      `${PRODUCT_STATUS_SERVICE_DOMAIN}/products/${productKey}/versions/${productVersion}/status:resolve`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorizationHeader,
        },
        body: JSON.stringify({
          contextSelections: {
            Country: culture.split('-')[1],
            Merchant: tenant,
          },
        }),
      }
    )

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    productStatus = await response.json()
  } catch {
    return 'ERROR'
  }

  return productStatus.status
}

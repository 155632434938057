import { FlexBox, Link, PipeSeparator, Typography } from '@vp/swan'
import { fetchUrlById } from '../../clients/urls.proxy'
import useDataAsync from '../../hooks/useDataAsync'
import { useLocalization } from '../../hooks/useLocalizations'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'

const NoProjectsRecommendations = () => {
  const { t } = useLocalization()
  const { tenant, locale } = useUserContext()
  const { identity } = useIdentity()
  const logger = useLogger()
  const businessCardUrl = useDataAsync(() => fetchUrlById(tenant as string, locale, 'businessCards', identity?.authorizationHeader as string, logger), [tenant, locale])
  const marketingMaterialsUrl = useDataAsync(
    () => fetchUrlById(tenant as string, locale, 'marketingMaterials', identity?.authorizationHeader as string, logger),
    [tenant, locale]
  )
  const invitationsAndStationeryUrl = useDataAsync(
    () => fetchUrlById(tenant as string, locale, 'invitationsAndStationery', identity?.authorizationHeader as string, logger),
    [tenant, locale]
  )

  return (
    <>
      <Typography mt='6'>{t('noProjects.readyToCreateText')}</Typography>
      <FlexBox mt='5' justifyContent='center'>
        <Link href={businessCardUrl}>{t('noProjects.businessCards')}</Link>
        <PipeSeparator marginX='3' />
        <Link href={marketingMaterialsUrl}>{t('noProjects.marketingMaterials')}</Link>
        <PipeSeparator marginX='3' />
        <Link href={invitationsAndStationeryUrl}>{t('noProjects.invitations')}</Link>
      </FlexBox>
    </>
  )
}

export default NoProjectsRecommendations

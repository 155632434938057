const ALBELLI_DOMAIN = 'https://albelli.design.vpsvc.com'

export interface AlbelliDoc {
  mpv: string;
  documentId: string;
  name: string;
  createdAt: number;
  lastUpdatedAt: number;
  vpEditUrl: string;
  locale?: null | string;
}

export const fetchAllAlbelliDocs = async (
  tenant: string,
  owner: string,
  authorizationHeader: string
): Promise<AlbelliDoc[]> => {
  if (tenant === 'vistaprint') {
    const response = await fetch(
      `${ALBELLI_DOMAIN}/api/v1/documents${owner ? `?ownerId=${owner}` : ''}`,
      {
        headers: {
          Authorization: authorizationHeader
        }
      }
    )
    if (!response.ok) {
      throw new Error('Couldn\'t fetch Albelli projects')
    }
    const fetchedProjects = await response.json() as { docs: AlbelliDoc[] }
    return fetchedProjects.docs
  } else {
    return []
  }
}

export async function deleteAlbelliDoc (projectId: string, authorizationHeader: string): Promise<void> {
  const response = await fetch(`${ALBELLI_DOMAIN}/api/v1/documents/${projectId}`, {
    method: 'DELETE',
    headers: {
      Authorization: authorizationHeader
    }
  })
  if (!response.ok) {
    throw new Error('Couldn\'t delete Albelli project')
  }
}

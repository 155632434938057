import { ModalDialogBody, ModalDialogButtons, ModalDialogFooter, Button, Box, Typography } from '@vp/swan'
import { useLocalization } from '../../hooks/useLocalizations'

interface RecreationModalDialogContentProps {
  onSubmit: () => void;
  onRequestDismiss: () => void;
}

export const RecreationModalDialogContent = ({ onSubmit, onRequestDismiss }: RecreationModalDialogContentProps) => {
  const { t } = useLocalization()
  return (
    <>
      <ModalDialogBody>
        <Box>
          <Typography textColor='standard' fontSkin='title-display' pb='6'>
            {t('legacyProjects.title-2')}
          </Typography>
          <Typography textColor='subtle' pb='6'>
            {t('legacyProjects.text1')}
          </Typography>
          <Typography textColor='subtle' pb='8'>
            {t('legacyProjects.text2')}
          </Typography>
        </Box>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button skin='primary' width='full-width' onClick={onSubmit} data-testid='recreation-modal-submit'>
              {t('legacyProjects.submit-2')}
            </Button>
            <Button skin='secondary' width='full-width' onClick={onRequestDismiss} data-testid='recreation-modal-skip'>
              {t('legacyProjects.skip')}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogBody>
    </>
  )
}

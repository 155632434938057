export const formatDate = (date: Date, culture: string) => {
  return date.toLocaleDateString(culture, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export const formatDateWithTime = (date: Date, culture: string) => {
  return date.toLocaleDateString(culture, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  })
}

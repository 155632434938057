import { Logger } from '@vp/ubik-logging'
import { Project } from '../model/project'
import { WesProject } from '../model/wesProject'
import { LogoMakerProject } from '../model/logoMakerProject'
import { renameProject as renameLogo } from '../services/logoMakerProjectService'
import { rename as renameWes } from '../services/wesProjectsService'

export const renameProject = async (
  project: Project,
  newName: string,
  tenant: string,
  owner: string,
  logger: Logger,
  authorizationHeader: string
) => {
  if (project instanceof LogoMakerProject) {
    await renameLogo(project, newName, logger, authorizationHeader)
  } else if (project instanceof WesProject) {
    await renameWes(project.id, newName, tenant, owner, logger, authorizationHeader)
  } else {
    logger.error(
      'Unknown project type for commands. You need to add all the project types to all the commands (renaming projects)',
      new Error('Unknown project type for commands (renaming projects).'),
      { project }
    )
    throw new Error('Unknown project type for commands.')
  }
}

import { Button, Icon, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@vp/swan'
import { ErrorInfo, useCallback, useContext, useState } from 'react'
import { CopyProjectModal } from './CopyProjectModal'
import { copyProject } from '..//../../domain/commands/copyCommand'
import { deleteProject } from '../../../domain/commands/deleteCommand'
import DeleteConfirmationModal from '../../actionButtons/DeleteConfirmationModal'
import { renameProject } from '../../../domain/commands/renameCommand'
import { RenameProjectModal } from './RenameProjectModal'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocalization } from '../../../hooks/useLocalizations'
import { Project } from '../../../domain/model/project'
import { useUserContext, useLogger, useIdentity } from '@vp/ubik-context'
import { AlertContext } from '../../../context/AlertContext'
import { useTracking } from '../../../context/TrackingContext'
import { useOwner } from '../../../hooks/useOwner'
import { trackProjectEvent, trackEventTiming } from '../../Analytics/trackingUtils'
import { ProjectListContext } from '../../../context/ProjectListContext'

enum ProjectTileDetailsModalActions {
  COPY = 'copy',
  DELETE = 'delete',
  RENAME = 'rename',
}

type ProjectTileActionMenuProps = {
  project: Project;
}

export const ProjectTileDetailsModalActionMenu = ({ project }: ProjectTileActionMenuProps) => {
  const { t } = useLocalization()
  const { tenant } = useUserContext()
  const logger = useLogger()
  const { identity } = useIdentity()

  const { refresh } = useContext(ProjectListContext)
  const { showAlert } = useContext(AlertContext)
  const { labels } = useTracking()
  const owner = useOwner()

  /* todo: This is a fix to prevent click events bleeding through the menu into the elements behind it
            Can be removed when the SWAN has been updated with the following
            https://gitlab.com/vistaprint-org/merchandising-technology/visage/swan/-/merge_requests/717
          */
  const onRefChange = useCallback((node: HTMLUListElement) => {
    if (node !== null) {
      if (node.parentElement?.classList.contains('swan-popover')) {
        const listener = (event: TouchEvent) => {
          event.preventDefault()
        }
        node.addEventListener('touchstart', listener, { passive: false })
      }
    }
  }, [])

  const copyProjectAndRefresh = async (newName: string) => {
    const copyNamePlaceholder = t('nameInputModal.copy.placeholder', { projectName: project.name })

    trackProjectEvent(labels.COPY, project)
    await trackEventTiming(labels.COPY, () =>
      copyProject(project, newName || copyNamePlaceholder, tenant as string, owner, logger, identity?.authorizationHeader as string)
    )
    refresh()
    setOpenModal(null)
  }

  const deleteProjectAndRefresh = async () => {
    try {
      trackProjectEvent(labels.DELETE, project)
      await deleteProject(project, identity?.authorizationHeader as string, logger)
      refresh()
      setOpenModal(null)
    } catch (error) {
      logger.error('Error deleting project', error as Error, { project })
      showAlert(t('deleteConfirmationModal.error'))
      setOpenModal(null)
    }
  }

  const renameProjectAndRefresh = async (newName: string) => {
    trackProjectEvent(labels.RENAME, project)
    await renameProject(project, newName, tenant as string, owner, logger, identity?.authorizationHeader as string)
    refresh()
    setOpenModal(null)
  }

  const [openModal, setOpenModal] = useState<ProjectTileDetailsModalActions | null>(null)

  const onModalError = (error: Error, errorInfo: ErrorInfo, project: Project) => {
    logger.error('Error in modal', error, { errorInfo, project })
  }

  const onMenuItemChange = (value: string | number) => {
    switch (value) {
      case ProjectTileDetailsModalActions.COPY:
        setOpenModal(ProjectTileDetailsModalActions.COPY)
        break
      case ProjectTileDetailsModalActions.DELETE:
        setOpenModal(ProjectTileDetailsModalActions.DELETE)
        break
      case ProjectTileDetailsModalActions.RENAME:
        setOpenModal(ProjectTileDetailsModalActions.RENAME)
        break
    }
  }

  return (
    <>
      {project.hasCopy && (
        <ErrorBoundary fallback={<></>} onError={(error, info) => onModalError(error, info, project)}>
          <CopyProjectModal
            project={project}
            isOpen={openModal === ProjectTileDetailsModalActions.COPY}
            onClose={() => setOpenModal(null)}
            onCopy={copyProjectAndRefresh}
          />
        </ErrorBoundary>
      )}
      {project.canBeDeleted && (
        <ErrorBoundary fallback={<></>} onError={(error, info) => onModalError(error, info, project)}>
          <DeleteConfirmationModal
            isOpen={openModal === ProjectTileDetailsModalActions.DELETE}
            onDelete={deleteProjectAndRefresh}
            onClose={() => setOpenModal(null)}
          />
        </ErrorBoundary>
      )}
      {project.canRename && (
        <ErrorBoundary fallback={<></>} onError={(error, info) => onModalError(error, info, project)}>
          <RenameProjectModal
            project={project}
            isOpen={openModal === ProjectTileDetailsModalActions.RENAME}
            onClose={() => setOpenModal(null)}
            onRename={renameProjectAndRefresh}
          />
        </ErrorBoundary>
      )}
      <Menu onChange={onMenuItemChange}>
        <MenuTrigger>
          <Button buttonShape='round' size='mini' aria-label={t('actionMenu')} data-testid='fly-out-action-item-button'>
            <Icon iconType='moreHorizontal' size='20p' />
          </Button>
        </MenuTrigger>
        <MenuPopover placement='bottom right'>
          <MenuList ref={onRefChange}>
            <MenuItem key={ProjectTileDetailsModalActions.RENAME} disabled={!project.canRename}>
              {t('actionButtons.rename')}
            </MenuItem>
            <MenuItem key={ProjectTileDetailsModalActions.COPY} disabled={!project.hasCopy}>
              {t('actionButtons.copy')}
            </MenuItem>
            <MenuItem
              key={ProjectTileDetailsModalActions.DELETE}
              disabled={!project.canBeDeleted}
              {...(project.canBeDeleted ? { textColor: 'error' } : {})}
            >
              {t('actionButtons.delete')}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  )
}

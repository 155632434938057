import {
  Card,
  GridContainer,
  Row,
  Column,
  Box,
  useScreenClass,
} from '@vp/swan'
import ProjectActions from '../actionButtons/ProjectActions'
import ProjectInfo from '../ProjectInfo'
import { ProjectImage } from './ProjectImage'
import { Project } from '../../domain/model/project'
import { useState } from 'react'
import ProjectDetailsModal from './ProjectDetailsModal'
import { trackProjectEvent } from '../Analytics/trackingUtils'
import { useTracking } from '../../context/TrackingContext'
import ProjectButtons from '../ProjectButtons'
import { useProjectsButtonExperienceVariation } from '../../hooks/useProjectsButtonExperienceVariation'
import { ON_DEMAND_GEN_AI_LOGO } from '../../constants'
import { LogoMakerProject } from '../../domain/model/logoMakerProject'
import { EditLink } from '../actionButtons/Edit'

type ProjectCardProps = {
  project: Project;
}

const ProjectCard = (props: ProjectCardProps) => {
  const { project } = props
  const { labels } = useTracking()

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const buttonsExperienceVariation = useProjectsButtonExperienceVariation()

  const openDetailModal = () => {
    trackProjectEvent(labels.OPEN_PROJECT_DETAILS_MODAL, project)
    setIsDetailsModalOpen(true)
  }
  const screenSize = useScreenClass()
  const isSmallDevice = screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md'

  return (
    <>
      <ProjectDetailsModal project={project} isOpen={isDetailsModalOpen} onClose={() => setIsDetailsModalOpen(false)} />
      <Card fullBleed={false} bordered component='section'>
        <GridContainer>
          <Row>
            <Column span={isSmallDevice ? 4 : 3} verticallyCenterContent>
              <ProjectImage project={project} onClick={openDetailModal} />
            </Column>
            <Column span={isSmallDevice ? 8 : 6} flexColumn>
              <Box style={{ marginLeft: isSmallDevice ? '0' : '25px' }}>
                <ProjectInfo project={project} buttonsExperienceVariation={buttonsExperienceVariation} />
                {/* Adding Edit link button here because of running Experiments for edit button.
                Because we want to show our non-editable button (in case of GenAi logo project) in project card
                below project info section always. */}
                {props.project instanceof LogoMakerProject &&
                  props.project.logoSource === ON_DEMAND_GEN_AI_LOGO && (
                    <EditLink project={project} />
                )}
                <ProjectButtons
                  project={project}
                  buttonsExperienceVariation={buttonsExperienceVariation}
                />
              </Box>
            </Column>
            {isSmallDevice && (
              <Row>
                <Column span={12}>
                  <ProjectActions project={project} buttonsExperienceVariation={buttonsExperienceVariation} />
                </Column>
              </Row>
            )}
            {!isSmallDevice && (
              <Column span={3}>
                <ProjectActions project={project} buttonsExperienceVariation={buttonsExperienceVariation} />
              </Column>
            )}
          </Row>
        </GridContainer>
      </Card>
    </>
  )
}

export default ProjectCard

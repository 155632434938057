import { Box, Divider, FlexBox, Typography, useScreenClass } from '@vp/swan'
import ProjectName from './card/ProjectName'
import moment from 'moment'
import Price from './Price'
import { isButtonsExperienceControlVariation } from '../utils/buttonsExperienceVariations'
import { useUserContext } from '@vp/ubik-context'
import { Project } from '../domain/model/project'
import { useLocalization } from '../hooks/useLocalizations'
import { LogoMakerProject } from '../domain/model/logoMakerProject'
import { formatDate, formatDateWithTime } from '../utils/dateFormat'
import { LOGO_STATUS } from '../constants'
import { mapLocaleToMomentLocale } from '../utils/localeMapperMomentJs'
import { useTranslatedProductOptions } from '../hooks/useTranslatedProductOptions'

interface ProjectInfoProps {
  project: Project;
  buttonsExperienceVariation: string;
}

const ProjectInfo = (props: ProjectInfoProps) => {
  const { project, buttonsExperienceVariation } = props

  const isControl = isButtonsExperienceControlVariation(buttonsExperienceVariation)

  const { locale } = useUserContext()

  const dateLocaleMoment = mapLocaleToMomentLocale(locale)
  moment.locale(dateLocaleMoment)

  const { t } = useLocalization()

  const translatedOptions = useTranslatedProductOptions(project)

  const screenSize = useScreenClass()
  const isMobile = screenSize === 'xs'

  return (
    <Box>
      <FlexBox mb='5' flexDirection='column' alignItems={isMobile ? 'center' : undefined}>
        <ProjectName project={project} />
      </FlexBox>
      <FlexBox mb='5' flexDirection='column' alignItems={isMobile ? 'center' : undefined}>
        <Typography fontSkin='body-standard-bold' title={formatDateWithTime(project.modified, locale)}>
          {t('projectInfo.editedTimeAgo', { timeAgo: moment(project.modified).fromNow() })}
        </Typography>
      </FlexBox>
      {project.productName && (
        <FlexBox flexDirection='column' alignItems={isMobile ? 'center' : undefined}>
          <Typography fontSkin='body-standard-bold' textColor='subtle'>
            {t('projectInfo.productName', { productName: project.productName })}
          </Typography>
        </FlexBox>
      )}
      <FlexBox flexDirection='column' alignItems={isMobile ? 'center' : undefined}>
        {project.options &&
          Object.keys(translatedOptions).map((option) => (
            <Typography
              key={option}
              fontSkin='body-small'
              textColor='subtle'
            >{`${option}: ${translatedOptions[option]}`}
            </Typography>
          ))}
      </FlexBox>
      {!isMobile && (
        <Typography mt='4' fontSkin='body-small' textColor='subtle'>
          {t('projectInfo.identificationNumber', { projectId: project.id })}
        </Typography>
      )}
      <FlexBox flexDirection='column' alignItems={isMobile ? 'center' : undefined}>
        <Typography mt='4' fontSkin='body-small' textColor='subtle'>
          {props.project.isFinished
            ? t('projectInfo.finishedDateInfo', {
                modifiedDate: formatDateWithTime(project.modified, locale),
              })
            : t('projectInfo.dateInfo', {
                createdDate: formatDate(project.created, locale),
              })}
        </Typography>
        {project instanceof LogoMakerProject && project.logoStatus === LOGO_STATUS.LOGO_ORDER_PROCESSING && (
          <Typography textColor='warning' fontFamily='primary' fontSkin='body-standard' mt='3'>
            {t('projectInfo.orderProcessing')}
          </Typography>
        )}
      </FlexBox>
      {!isControl && (
        <>
          <Divider my='4' />
          <FlexBox flexDirection='column' alignItems={isMobile ? 'center' : undefined}>
            {project.isMerchandisingAvailable && (
              <Price project={project} buttonsExperienceVariation={buttonsExperienceVariation} />
            )}
          </FlexBox>
        </>
      )}
    </Box>
  )
}

export default ProjectInfo

import { PurcsApi } from '@vp/react-product-preview'
import { LazyLoadingImage, ResponsiveBadge } from '../../LazyLoadingImage'
import { Project } from '../../../domain/model/project'
import { useUserContext } from '@vp/ubik-context'
import { WesProject } from '../../../domain/model/wesProject'
import { REQUESTOR } from '../../../constants'

const PURCS_VISTAPRINT_TENANT = 'vistaprint-prod'
const PURCS_PROMOTIQUE_TENANT = 'promotique-prod'

const getPurcsTenant = (tenant: string) =>
  purcsTenants[tenant] || purcsTenants.default

const purcsTenants: Record<string, string> = {
  vistaprint: PURCS_VISTAPRINT_TENANT,
  promotique: PURCS_PROMOTIQUE_TENANT,
  brandstore: PURCS_PROMOTIQUE_TENANT,
  default: PURCS_VISTAPRINT_TENANT,
}

type SingleProjectImageProps = {
  project: Project;
}

export const SingleProjectImage = ({ project }: SingleProjectImageProps) => {
  if (project.imageUrl) {
    return (
      <LazyLoadingImage
        src={project.imageUrl}
        alt={project.name}
        hasBadge={project.isFinished}
        badgeComponent={ResponsiveBadge}
      />
    )
  }
  return <PurcsImage project={project} />
}

const PURCS_URL = 'https://purcs.design.vpsvc.com'
const PURPOSE = 'design.studio.review'

const PurcsImage = ({ project }: { project: Project }) => {
  const { locale, tenant } = useUserContext()

  let optionSelections = {}
  let displayUrl = ''

  if (project instanceof WesProject) {
    optionSelections = project.options
    displayUrl = project.displayUrl
  }

  const purcsPreview = PurcsApi.getDefaultView(
    PURCS_URL,
    getPurcsTenant(tenant as string),
    locale,
    project.productKey,
    project.productVersion,
    optionSelections,
    REQUESTOR,
    PURPOSE,
    displayUrl,
    500
  )

  return (
    <LazyLoadingImage
      src={purcsPreview?.src || ''}
      srcSet={purcsPreview?.srcSet}
      alt={project.name}
      hasBadge={false}
    />
  )
}

import { ModalDialog } from '@vp/swan'
import CenteredSpinner from './CenteredSpinner'

function LoadingOverlay () {
  return (
    <ModalDialog isOpen onRequestDismiss={() => {}} onlyRenderWhenOpen>
      <CenteredSpinner />
    </ModalDialog>
  )
}

export default LoadingOverlay

import { Column, GridContainer, Row, ScreenClassProvider, SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { ExperimentContext } from '../domain/model/Ubik'
import MyProjects from './MyProjects'

export interface MyProjectFragmentProps {
  locale: string
  experiments?: ExperimentContext
  localizations: { [key: string]: any }
}

export const Fragment = (props: MyProjectFragmentProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.progressBar,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.pagination,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.spinner
  ])

  return (
    <>
      <ScreenClassProvider>
        <GridContainer>
          <Row mb={9}>
            <Column span={12}>
              <MyProjects />
            </Column>
          </Row>
        </GridContainer>
      </ScreenClassProvider>
    </>
  )
}

import {
  Box,
  Button,
  Icon,
  FlexBox,
  Link,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  Typography,
  useScreenClass,
} from '@vp/swan'
import { useContext, useState } from 'react'
import { LegacyProject } from '../../domain/model/legacyProject'
import { getAllLegacyProjects } from '../../domain/services/legacyProjectService'
import { fetchDesignDashboardUrl } from '../../clients/urls.proxy'
import { ProjectListContext } from '../../context/ProjectListContext'
import useDataAsync from '../../hooks/useDataAsync'
import LoadingOverlay from '../spinners/LoadingOverlay'
import LegacyProjectCard from './LegacyProjectCard'
import { PromoBar } from './PromoBar'
import { ITEMS_PER_PAGE_OPTIONS, PROJECTS_CONTAINED_ELEMENT_ID } from '../../constants'
import ItemsPagination from '../ItemsPagination'
import { useLocalization } from '../../hooks/useLocalizations'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { useOwner } from '../../hooks/useOwner'

const LegacyProjectsList = () => {
  const { t } = useLocalization()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [visibleProjects, setVisibleProjects] = useState<LegacyProject[]>([])
  const [refreshToggle, setRefreshToggle] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const { locale, tenant } = useUserContext()
  const owner = useOwner()
  const logger = useLogger()
  const { identity } = useIdentity()

  const { refresh } = useContext(ProjectListContext)

  const designServiceUrl = useDataAsync(() => fetchDesignDashboardUrl(tenant as string, locale, identity?.authorizationHeader as string, logger), [tenant, locale])

  const legacyProjects = useDataAsync(async () => {
    setIsRefreshing(true)
    const projects = await getAllLegacyProjects(locale, owner, identity?.authorizationHeader as string, logger)
    setIsRefreshing(false)
    return projects
  }, [locale, tenant, owner, refreshToggle])
  const refreshLegacyProject = () => {
    setRefreshToggle(!refreshToggle)
    refresh()
  }

  const screenSize = useScreenClass()
  const promoBarFlexDirection = screenSize === 'xs' || screenSize === 'sm' ? 'column' : 'row'

  const onVisibleItemsChangedHandler = (items: LegacyProject[]) => setVisibleProjects(items)

  if (legacyProjects === undefined || !legacyProjects.count()) return null

  const designHelpText = t('legacyProjects.designHelp')
  const designHelpLinkText = t('legacyProjects.designHelpLink')

  return (
    <Box id={PROJECTS_CONTAINED_ELEMENT_ID}>
      {isRefreshing && <LoadingOverlay />}
      <PromoBar bgc='strong' marginBottom='7' flexWrap='wrap' flexDirection={promoBarFlexDirection}>
        <Icon skin='standard' iconType='info' ml='5' size='20p' />
        <Typography fontSkin='body-standard-bold' textAlign='center'>
          {t('legacyProjects.promoBarText')}
        </Typography>
        <Link onClick={() => setIsModalOpen(true)} skin='standard'>
          {t('legacyProjects.promoBarLink')}
        </Link>
      </PromoBar>
      <ModalDialog
        onRequestDismiss={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        variant='panel-right'
        onlyRenderWhenOpen
      >
        <ModalDialogContent aria-label={t('legacyProjects.title')}>
          <ModalDialogCloseButton visuallyHiddenLabel={t('legacyProjects.close')} />
          <ModalDialogHeader>{t('legacyProjects.title')}</ModalDialogHeader>
          <ModalDialogBody>
            <Typography>{t('legacyProjects.subtitle')}</Typography>
            <Typography marginY='6'>
              {`${designHelpText} `}
              <Link href={designServiceUrl}>
                {designHelpLinkText}
              </Link>
            </Typography>
          </ModalDialogBody>
          <FlexBox flexDirection='column' alignItems='center' my='3'>
            <ItemsPagination
              items={legacyProjects.toArray()}
              itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
              onVisibleItemsChanged={onVisibleItemsChangedHandler}
              parentId={PROJECTS_CONTAINED_ELEMENT_ID}
            />

            <Box>
              {visibleProjects.map((project: LegacyProject) => (
                <Box marginY='3' key={project.id}>
                  <LegacyProjectCard project={project} onRefreshRequested={refreshLegacyProject} />
                </Box>
              ))}
            </Box>

            <ItemsPagination
              items={legacyProjects.toArray()}
              itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
              onVisibleItemsChanged={onVisibleItemsChangedHandler}
              parentId={PROJECTS_CONTAINED_ELEMENT_ID}
            />
            <Button skin='primary' marginY='4' onClick={() => setIsModalOpen(false)}>
              {t('legacyProjects.closeButton')}
            </Button>
          </FlexBox>
        </ModalDialogContent>
      </ModalDialog>
    </Box>
  )
}

export default LegacyProjectsList

import { ErrorInfo, useContext } from 'react'
import { ScreenClassProvider } from '@vp/swan'
import { useIdentity, useLogger, useUserContext } from '@vp/ubik-context'
import { DesignServicesProvider } from '@vp/dst-my-project-placement'
import { AlertContextProvider } from '../context/AlertContext'
import { ProjectListContext, ProjectListContextProvider } from '../context/ProjectListContext'
import NotSignedIn from './noProjects/NotSignedIn'
import LegacyProjectsList from './legacyProjects/LegacyProjectsList'
import NoResponse from './noProjects/NoResponse'
import { ErrorBoundary } from 'react-error-boundary'
import { ProjectsTileList } from './ProjectsTileList'
import LoadingOverlay from './spinners/LoadingOverlay'
import NoProjects from './noProjects/NoProjects'
import { ProjectsList } from './ProjectsList'

function MyProjects () {
  const { identity } = useIdentity()
  const { locale } = useUserContext()

  if (!identity?.authorizationHeader) {
    return <></>
  }

  const isSignedIn = identity?.isSignedIn
  return (
    <DesignServicesProvider culture={locale} authorizationHeader={identity?.authorizationHeader}>
      <AlertContextProvider>
        {!isSignedIn && <NotSignedIn />}
        {isSignedIn && (
          <ProjectListContextProvider>
            <LegacyProjectsList />
            <ScreenClassProvider>
              <PaginatedProjectsListWrapper />
            </ScreenClassProvider>
          </ProjectListContextProvider>
        )}
      </AlertContextProvider>
    </DesignServicesProvider>
  )
}

function PaginatedProjectsListWrapper () {
  const { projectsList, isLoadingFirstPage, hasFinishedLoading, noProjectsError } = useContext(ProjectListContext)

  const { experiments } = useUserContext()
  const logger = useLogger()

  const projectListHasChanged = !!projectsList.count() && isLoadingFirstPage
  const noProjectsAfterLoading = hasFinishedLoading && projectsList.count() === 0

  // This is experiment is not currently available and is purely here to rollback the previous experiment while
  // maintaining the implementation. Remove this if we decide not to go ahead with this
  const shouldShowNewProjectTiles = !!experiments && experiments['my_acct_my_projects_tiles_v2'] === 'test'

  const onProjectTileListRenderError = (error: Error, errorInfo: ErrorInfo) => {
    logger.error('failed to render users projects', error, { errorInfo })
  }
  return (
    <>
      {noProjectsError && <NoResponse />}
      {!noProjectsAfterLoading &&
        (shouldShowNewProjectTiles
          ? (
            <ErrorBoundary fallback={<NoResponse />} onError={onProjectTileListRenderError}>
              <ProjectsTileList projectsList={projectsList} hasFinishedLoading={hasFinishedLoading} />
            </ErrorBoundary>
            )
          : (
            <ProjectsList projectsList={projectsList} hasFinishedLoading={hasFinishedLoading} />
            ))}
      {projectListHasChanged && <LoadingOverlay />}
      {noProjectsAfterLoading && <NoProjects />}
    </>
  )
}

export default MyProjects
